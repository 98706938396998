// import React, { useState, useEffect, useRef } from "react";
// import { AnimatePresence, motion } from "framer-motion";
// import axios from "axios";
// import { useNavigate,  useLocation } from "react-router-dom";

// let postRequestSent = false;

// const CheckFilled = ({ className }) => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//       fill="currentColor"
//       className={className}
//     >
//       <path
//         fillRule="evenodd"
//         d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
//         clipRule="evenodd"
//       />
//     </svg>
//   );
// };

// export default function Workflow() {
//   const [messages, setMessages] = useState([]);
//   const bottomRef = useRef(null);
//   const navigate = useNavigate();
//   const [showButton, setShowButton] = useState(false);
//   const location = useLocation();
//   const requirementId = location.state?.requirement;

//   console.log("requirement id in Workflow:", requirementId);

//   useEffect(() => {
//     if (!postRequestSent) {
//       console.log("req_id", requirementId);
//       axios
//         .post("http://air.talanton.ai:4000/workflow", {
//           requirement_id: requirementId.toString(),
//         })
//         .catch((error) => {
//           console.error("Error posting to workflow:", error);
//         });
//       postRequestSent = true;
//     }
//   }, [requirementId]);

//   useEffect(() => {
//     // Clear local storage to avoid caching data from previous sessions
//     localStorage.removeItem('candidateData');

//     const newSocket = new WebSocket("ws://air.talanton.ai:4000/ws2");
//     const uniqueMessages = new Set();
//     newSocket.onmessage = (event) => {
//       const message = event.data;
//       console.log("Received message:", message);
//       if (message.startsWith("Candidate JSON :")) {
//         try {
//           const candidateData = JSON.parse(message.replace("Candidate JSON : ", ""));
//           const name = candidateData.Name;
//           const dynamicMessage = `Toingg calling agent just had a call with ${name}. You can see details in Candidate Profile page`;
//           setMessages((prevMessages) => [...prevMessages, { text: dynamicMessage }]);
//         } catch (error) {
//           console.error("Error parsing candidate JSON:", error);
//         }
//       } else if (!uniqueMessages.has(message)) {
//         uniqueMessages.add(message);
//         setMessages((prevMessages) => [...prevMessages, { text: message }]);
//         if (message.startsWith("AI Profiling Agent is now analyzing the profiles.")) {
//           setShowButton(true);
//         }
//         // if (message.includes("TOINGG Calling Agent is now interviewing the 1st candidate.")) {
//         //   newSocket.close();
//         // }
//       }
//     };
//     newSocket.onerror = (event) => {
//       console.error("WebSocket error:", event);
//     };
//     newSocket.onclose = (event) => {
//       console.log("WebSocket closed:", event);
//     };
//     return () => {
//       newSocket.close();
//     };
//   }, []);

//   useEffect(() => {
//     bottomRef.current?.scrollIntoView({ behavior: "smooth" });
//   }, [messages]);

//   const handleButtonClick = () => {
//     window.open("/CandidatesPage", "_blank");
//   };

//   return (
//     <AnimatePresence mode="wait">
//       <motion.div
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         exit={{ opacity: 0 }}
//         className="w-full h-full fixed inset-0 z-[100] flex items-center justify-center backdrop-blur-2xl relative"
//       >
//         {showButton && (
//           <button
//             onClick={handleButtonClick}
//             className="absolute top-4 right-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
//           >
//             Candidate Profiles
//           </button>
//         )}
//         <div className="relative w-full max-w-xl mx-auto">
//           <div className="custom-scrollbar overflow-auto h-[320px]">
//             <div className="flex flex-col">
//               {messages.map((message, index) => (
//                 <div
//                   key={index}
//                   initial={{ opacity: 0, y: 50 }}
//                   animate={{ opacity: 1, y: 0 }}
//                   exit={{ opacity: 0, y: -50 }}
//                   transition={{ duration: 0.5 }}
//                   className="text-left flex gap-2 mb-4"
//                 >
//                   <CheckFilled
//                     className={
//                       index === messages.length - 1
//                         ? "w-6 h-6 text-white dark:text-lime-500"
//                         : "w-6 h-6 text-white dark:text-white-500"
//                     }
//                   />
//                   <span
//                     className={
//                       index === messages.length - 1
//                         ? "text-xl text-lime-500"
//                         : "text-md text-white"
//                     }
//                   >
//                     {message.text}
//                   </span>
//                 </div>
//               ))}
//               <div ref={bottomRef} />
//             </div>
//           </div>
//         </div>
//       </motion.div>
//     </AnimatePresence>
//   );
// }

// import React, { useState, useEffect, useRef } from "react";
// import { AnimatePresence, motion } from "framer-motion";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// let postRequestSent = false;

// const CheckFilled = ({ className }) => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//       fill="currentColor"
//       className={className}
//     >
//       <path
//         fillRule="evenodd"
//         d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
//         clipRule="evenodd"
//       />
//     </svg>
//   );
// };

// export default function Workflow({ requirementId }) {
//   const [messages, setMessages] = useState([]);
//   const bottomRef = useRef(null);
//   const navigate = useNavigate();
//   const [showButton, setShowButton] = useState(false);

//   useEffect(() => {
//     if (!postRequestSent) {
//       console.log("req_id", requirementId);
//       axios
//         .post("http://air.talanton.ai:4000/workflow", {
//           requirement_id: requirementId.toString(),
//         })
//         .catch((error) => {
//           console.error("Error posting to workflow:", error);
//         });
//       postRequestSent = true;
//     }
//   }, [requirementId]);

//   useEffect(() => {
//     // Clear local storage to avoid caching data from previous sessions
//     localStorage.removeItem('candidateData');

//     const newSocket = new WebSocket("ws://air.talanton.ai:4000/ws2");
//     const uniqueMessages = new Set();
//     newSocket.onmessage = (event) => {
//       const message = event.data;
//       console.log("Received message:", message);

//       const messageParts = message.split(' ');
//       const messageRequirementId = messageParts[messageParts.length - 1];

//       if (messageRequirementId === requirementId.toString()) {
//         if (message.startsWith("Candidate JSON :")) {
//           try {
//             const candidateData = JSON.parse(message.replace("Candidate JSON : ", ""));
//             const name = candidateData.Name;
//             const dynamicMessage = `Toingg calling agent just had a call with ${name}. You can see details in Candidate Profile page`;
//             setMessages((prevMessages) => [...prevMessages, { text: dynamicMessage }]);
//           } catch (error) {
//             console.error("Error parsing candidate JSON:", error);
//           }
//         } else if (!uniqueMessages.has(message)) {
//           uniqueMessages.add(message);
//           setMessages((prevMessages) => [...prevMessages, { text: message }]);
//           if (message.startsWith("AI Profiling Agent is now analyzing the profiles.")) {
//             setShowButton(true);
//           }
//           if (message.includes("TOINGG Calling Agent is now interviewing the 1st candidate.")) {
//             newSocket.close();
//           }
//         }
//       }
//     };
//     newSocket.onerror = (event) => {
//       console.error("WebSocket error:", event);
//     };
//     newSocket.onclose = (event) => {
//       console.log("WebSocket closed:", event);
//     };
//     return () => {
//       newSocket.close();
//     };
//   }, [requirementId]);

//   useEffect(() => {
//     bottomRef.current?.scrollIntoView({ behavior: "smooth" });
//   }, [messages]);

//   const handleButtonClick = () => {
//     window.open("/CandidatesPage", "_blank");
//   };

//   return (
//     <AnimatePresence mode="wait">
//       <motion.div
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         exit={{ opacity: 0 }}
//         className="w-full h-full fixed inset-0 z-[100] flex items-center justify-center backdrop-blur-2xl relative"
//       >
//         {showButton && (
//           <button
//             onClick={handleButtonClick}
//             className="absolute top-4 right-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
//           >
//             Candidate Profiles
//           </button>
//         )}
//         <div className="relative w-full max-w-xl mx-auto">
//           <div className="custom-scrollbar overflow-auto h-[320px]">
//             <div className="flex flex-col">
//               {messages.map((message, index) => (
//                 <motion.div
//                   key={index}
//                   initial={{ opacity: 0, y: 50 }}
//                   animate={{ opacity: 1, y: 0 }}
//                   exit={{ opacity: 0, y: -50 }}
//                   transition={{ duration: 0.5 }}
//                   className="text-left flex gap-2 mb-4"
//                 >
//                   <CheckFilled
//                     className={
//                       index === messages.length - 1
//                         ? "w-6 h-6 text-white dark:text-lime-500"
//                         : "w-6 h-6 text-white dark:text-white-500"
//                     }
//                   />
//                   <span
//                     className={
//                       index === messages.length - 1
//                         ? "text-xl text-lime-500"
//                         : "text-md text-white"
//                     }
//                   >
//                     {message.text}
//                   </span>
//                 </motion.div>
//               ))}
//               <div ref={bottomRef} />
//             </div>
//           </div>
//         </div>
//       </motion.div>
//     </AnimatePresence>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { useNavigate } from "react-router-dom";

let postRequestSent = false;

const CheckFilled = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default function Workflow({ requirementId }) {
  const [messages, setMessages] = useState([]);
  const bottomRef = useRef(null);
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (!postRequestSent) {
      console.log("req_id", requirementId);
      axios
        .post("http://air.talanton.ai:4000/workflow", {
          requirement_id: requirementId.toString(),
        })
        .catch((error) => {
          console.error("Error posting to workflow:", error);
        });
      postRequestSent = true;
    }
  }, [requirementId]);

  useEffect(() => {
    // Clear local storage to avoid caching data from previous sessions
    localStorage.removeItem("candidateData");

    const newSocket = new WebSocket("ws://air.talanton.ai:4000/ws2");
    const uniqueMessages = new Set();
    newSocket.onmessage = (event) => {
      const message = event.data;
      console.log("Received message:", message);

      const messageParts = message.split(" ");
      const messageRequirementId = messageParts[messageParts.length - 1];

      if (messageRequirementId === requirementId.toString()) {
        const actualMessage = messageParts.slice(0, -1).join(" ");

        if (actualMessage.startsWith("Candidate JSON :")) {
          try {
            const candidateData = JSON.parse(
              actualMessage.replace("Candidate JSON : ", "")
            );
            const name = candidateData.Name;
            const dynamicMessage = `Toingg calling agent just had a call with ${name}. You can see details in Candidate Profile page`;
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: dynamicMessage },
            ]);
          } catch (error) {
            console.error("Error parsing candidate JSON:", error);
          }
        } else if (!uniqueMessages.has(actualMessage)) {
          uniqueMessages.add(actualMessage);
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: actualMessage },
          ]);
          if (
            actualMessage.startsWith(
              "AI Profiling Agent is now analyzing the profiles."
            )
          ) {
            setShowButton(true);
          }
          if (
            actualMessage.includes(
              "TOINGG Calling Agent is now interviewing the 1st candidate."
            )
          ) {
            newSocket.close();
          }
        }
      }
    };
    newSocket.onerror = (event) => {
      console.error("WebSocket error:", event);
    };
    newSocket.onclose = (event) => {
      console.log("WebSocket closed:", event);
    };
    return () => {
      newSocket.close();
    };
  }, [requirementId]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleButtonClick = () => {
    window.open("/CandidatesPage", "_blank");
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="w-full h-full fixed inset-0 z-[100] flex items-center justify-center backdrop-blur-2xl relative"
      >
        {showButton && (
          <button
            onClick={handleButtonClick}
            className="absolute top-4 right-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          >
            Candidate Profiles
          </button>
        )}
        <div className="relative w-full max-w-xl mx-auto">
          <div className="custom-scrollbar overflow-auto h-[320px]">
            <div className="flex flex-col">
              {messages.map((message, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                  className="text-left flex gap-2 mb-4"
                >
                  <CheckFilled
                    className={
                      index === messages.length - 1
                        ? "w-6 h-6 text-white dark:text-lime-500"
                        : "w-6 h-6 text-white dark:text-white-500"
                    }
                  />
                  <span
                    className={
                      index === messages.length - 1
                        ? "text-xl text-lime-500"
                        : "text-md text-white"
                    }
                  >
                    {message.text}
                  </span>
                </motion.div>
              ))}
              <div ref={bottomRef} />
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
