// import React, { useState } from 'react';
// import './App.css';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Chat from './components/Chat';
// import Sidebar from './components/Sidebar';
// import Subscribe from './components/subscribe';
// import Workflow from './components/Workflow';
// import ChatComponent from './components/ChatComponent';
// import PaymentStructure from './components/PaymentStructure';
// import PaymentSuccess from './components/PaymentSuccess';
// import CandidatesPage from './components/CandidatesPage';



// function App() {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [requirementId, setRequirementId] = useState('');


//   const handleRequirementIdExtracted = (id) => {
//     setRequirementId(id);
//   };
  
//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <Router className="bg-[#001100]">
//       <div className="flex bg-[#001100] w-full min-h-screen">
//         {/* <Sidebar isOpen={isSidebarOpen} /> */}
//         <div className="flex w-full">
//           <button onClick={toggleSidebar} className="relative top-1/2 transform -translate-y-1/2 right-0 z-10 bg-[#001100] text-white p-0 rounded">
//             {isSidebarOpen ? (
//               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <path opacity="0.4" d="M13.2602 15.5302L9.74023 12.0002L13.2602 8.47021" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
//             ) : (
//               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#f7f7f7" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> <path opacity="0.4" d="M10.7402 15.5302L14.2602 12.0002L10.7402 8.47021" stroke="#f7f7f7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
//             )}
//           </button>
//           <div className="left-12 w-full">
//             <Routes>
//               {/* <Route path='/' element={<Chat isOpen={isSidebarOpen} />} /> */}
//               <Route
//                  path="/"
//                  element={<Chat onRequirementIdExtracted={handleRequirementIdExtracted} isOpen={isSidebarOpen}/>}
//               />
//               <Route path='/paymentstructure' element={<PaymentStructure />} />
//               <Route path='/subscribe' element={<Subscribe />} />
//               <Route path='/paymentsuccess' element={<PaymentSuccess/>} />
//               <Route path='/workflow' element={<Workflow requirementId={requirementId} />} />
//               <Route path='/streaming' element={<ChatComponent />} />
//               <Route path='/candidatespage' element={<CandidatesPage />} />

//               {/* <Route path="/CandidateWorkflow" element={<CandidateWorkflow />} /> */}

//             </Routes>
//           </div>
//         </div>
//       </div>
//     </Router>
//   );
// }

// export default App;



// without side bar 


import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './components/Chat';
import Subscribe from './components/subscribe';
import Workflow from './components/Workflow';
import ChatComponent from './components/ChatComponent';
import PaymentStructure from './components/PaymentStructure';
import PaymentSuccess from './components/PaymentSuccess';
import CandidatesPage from './components/CandidatesPage';

function App() {
  const [requirementId, setRequirementId] = useState('');

  const handleRequirementIdExtracted = (id) => {
    setRequirementId(id);
  };

  return (
    <Router className="bg-[#001100]">
      <div className="flex bg-[#001100] w-full min-h-screen">
        <div className="flex w-full">
          <div className="w-full">
            <Routes>
              <Route
                path="/"
                element={<Chat onRequirementIdExtracted={handleRequirementIdExtracted} />}
              />
              <Route path='/paymentstructure' element={<PaymentStructure />} />
              <Route path='/subscribe' element={<Subscribe />} />
              <Route path='/paymentsuccess' element={<PaymentSuccess/>} />
              <Route path='/workflow' element={<Workflow requirementId={requirementId} />} />
              <Route path='/streaming' element={<ChatComponent />} />
              <Route path='/candidatespage' element={<CandidatesPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;