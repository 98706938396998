// import React, { useState, useRef, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
// import gfm from 'remark-gfm';
// import getFingerprint from './browserFingerprint';

// const Chat = ({ isOpen, onRequirementIdExtracted }) => {
//   const [messages, setMessages] = useState([{ text: "Hi, I am HireXtra. How can I help you today?", isUser: false }]);
//   const [userInput, setUserInput] = useState('');
//   const messagesEndRef = useRef(null);
//   const inputRef = useRef(null);  // Ref for focusing on textarea
//   const [uploadedFileName, setUploadedFileName] = useState('');
//   const [qaSessionStarted, setQaSessionStarted] = useState(false);
//   const [jobDescriptionUploaded, setJobDescriptionUploaded] = useState(false);
//   const [summaryResponseGiven, setSummaryResponseGiven] = useState(false);
//   const [isSending, setIsSending] = useState(false);
//   const [isUploading, setIsUploading] = useState(false);
//   const [fingerprint, setFingerprint] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [renderedMessages, setRenderedMessages] = useState(new Set()); // Track rendered messages
//   const navigate = useNavigate();
//   const fingerprintGenerated = useRef(false);
//   const wsRef = useRef(null); // Ref to store WebSocket instance

//   useEffect(() => {
//     scrollToBottom();
//   }, [messages]);

//   useEffect(() => {
//     if (inputRef.current) {
//       inputRef.current.focus();  // Auto-focus on textarea after sending a message
//     }
//   }, [messages]);

//   useEffect(() => {
//     const generateFingerprint = async () => {
//       if (!fingerprintGenerated.current) {
//         const fp = await getFingerprint();
//         setFingerprint(fp);
//         console.log('Window Fingerprint:', fp);
//         fingerprintGenerated.current = true;
//       }
//     };

//     generateFingerprint();
//   }, []);

//   useEffect(() => {
//     if (wsRef.current) {
//       wsRef.current.close();
//     }

//     wsRef.current = new WebSocket('ws://air.talanton.ai:5000/stream');

//     wsRef.current.onmessage = (event) => {
//       const newMessage = event.data.replace("Data received as: ", "").trim();
//       setMessages((prevMessages) => {
//         const lastMessage = prevMessages[prevMessages.length - 1];
//         if (!lastMessage.isUser) {
//           const updatedMessage = { ...lastMessage, text: newMessage };
//           return [...prevMessages.slice(0, -1), updatedMessage];
//         } else {
//           if (newMessage.includes("Job Requirement ID:") && renderedMessages.has(newMessage)) {
//             return prevMessages;
//           } else if (newMessage.includes("could you please specify the industry this position is associated with") && renderedMessages.has(newMessage)) {
//             return prevMessages;
//           } else {
//             setRenderedMessages((prevRendered) => new Set(prevRendered).add(newMessage));
//             return [...prevMessages, { text: newMessage, isUser: false }];
//           }
//         }
//       });
//     };

//     wsRef.current.onclose = () => {
//       console.log('WebSocket connection closed');
//     };

//     return () => {
//       if (wsRef.current) {
//         wsRef.current.close();
//       }
//     };
//   }, [renderedMessages]);

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };

//   const handleInputChange = (e) => {
//     setUserInput(e.target.value);
//   };

//   const handleNavigate = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://air.talanton.ai:8000', {
//         content: "move to payment"
//       });
//       const priceData = response.data;
//       navigate('/paymentstructure', { state: { priceData } });
//     } catch (error) {
//       console.error('Error moving to payment structure:', error);
//     }
//     setIsLoading(false);
//   };

//   const handleFileChange = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setIsUploading(true);
//       setIsLoading(true);
//       setUploadedFileName(file.name);
//       const formData = new FormData();
//       formData.append('uploaded_file', file);

//       const uploadResponse = await axios.post('http://air.talanton.ai:8000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });

//       const response = await axios.post('http://air.talanton.ai:8000', {
//         content: `upload jd ${uploadResponse.data}`
//       });

//       const hirexMessage = {
//         text: response.data.message,
//         isUser: false
//       };

//       setMessages(prevMessages => [...prevMessages, hirexMessage]);
//       setJobDescriptionUploaded(true);
//       setIsUploading(false);
//       setIsLoading(false);
//     }
//   };

//   const startQaSession = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://air.talanton.ai:8000', {
//         content: "Start Q&A"
//       });

//       const hirexMessage = {
//         text: response.data.message,
//         isUser: false
//       };
//       setMessages(prevMessages => {
//         if (renderedMessages.has(hirexMessage.text)) {
//           return prevMessages;
//         } else {
//           setRenderedMessages((prevRendered) => new Set(prevRendered).add(hirexMessage.text));
//           return [...prevMessages, hirexMessage];
//         }
//       });
//       setQaSessionStarted(true);
//     } catch (error) {
//       console.error('Error starting Q&A session:', error);
//     }
//     setIsLoading(false);
//   };

//   const handleSubmit = async () => {
//     setUserInput('');   // Clear the text area
//     if (!isSending && userInput.trim() !== '') {
//       setIsSending(true);
//       const userMessage = { text: userInput, isUser: true };
//       setMessages(prevMessages => [...prevMessages, userMessage]);

//       try {
//         await axios.post('http://air.talanton.ai:8000', {
//           content: userInput,
//           session_token: fingerprint
//         });

//         setIsSending(false);
//       } catch (error) {
//         console.error('Error sending message:', error);
//         setIsSending(false);
//       }
//     }
//   };

//   const handleSummaryResponse = async (agreed) => {
//     const responseText = agreed ? "It's perfect" : "It's not perfect";
//     setIsLoading(true);

//     try {
//       const response = await axios.post('http://air.talanton.ai:8000', {
//         content: responseText
//       });

//       const message = {
//         text: response.data.message,
//         isUser: false
//       };

//       setMessages(prevMessages => {
//         if (renderedMessages.has(message.text)) {
//           return prevMessages;
//         } else {
//           setRenderedMessages((prevRendered) => new Set(prevRendered).add(message.text));
//           return [...prevMessages, message];
//         }
//       });

//       if (response.data.message.includes("Job Requirement ID:")) {
//         const extractedId = response.data.message.split("Job Requirement ID:")[1].split('\n')[0].trim();
//         onRequirementIdExtracted(extractedId);
//       }
//       setSummaryResponseGiven(true);
//     } catch (error) {
//       console.error('Error handling summary response:', error);
//     }
//     setIsLoading(false);
//   };

//   // Modifications to the textarea
//   const adjustTextareaHeight = (e) => {
//     const textarea = e.target;
//     textarea.style.height = '50px';  // Reset the height to the minimum value
//     textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`;  // Set to the scroll height or maximum height
//     if (textarea.scrollHeight > textarea.offsetHeight) {
//       textarea.style.overflowY = 'scroll';  // Enable scrolling when the textarea reaches its maximum height
//     } else {
//       textarea.style.overflowY = 'hidden';  // Hide scrolling when the textarea is smaller than its maximum height
//     }
//   };

//   const renderMessages = () => {
//     return messages.map((message, index) => {
//       const lowercaseMessage = (message.text || '').toLowerCase();
//       const keywords = ["upload your job description"];
//       const needsJobDescription = keywords.some(keyword => lowercaseMessage.includes(keyword));
//       const containsKeywordSummary = lowercaseMessage.includes('summary of the hiring requirements');
//       const isMoveToPayment = lowercaseMessage.includes('move to payment');

//       return (
//         <React.Fragment key={index}>
//           {message.isUser ? (
//             <div className="flex justify-end mb-4 mr-1/5">
//               <div className="bg-[#D9D9D921] text-white text-sm px-6 py-2 rounded-3xl ml-4 max-w-[80%]">
//                 {message.text}
//               </div>
//               <img src="/images/Ellipse 13.png" alt="User" className="w-10 h-10 rounded-full ml-2" />
//             </div>
//           ) : (
//             <React.Fragment>
//               <div className="flex justify-start mb-4">
//                 <img src="/images/image 23.png" alt="Hirex" className="w-10 h-10 rounded-full mr-2" />
//                 <div className="bg-[#D9D9D921] text-white text-sm px-6 py-2 rounded-3xl mr-4 max-w-[80%]">
//                   <ReactMarkdown remarkPlugins={[gfm]}>{message.text}</ReactMarkdown>
//                 </div>
//               </div>

//               {needsJobDescription && !jobDescriptionUploaded && !qaSessionStarted && (
//                 <div className='ml-12 sm:flex sm:flex-col sm:items-center sm:gap-2 mb-4'>
//                   <div className="sm:flex sm:gap-2">
//                     <div className="items-center">
//                       <input
//                         className="hidden"
//                         type="file"
//                         id="file_input"
//                         onChange={handleFileChange}
//                       />
//                       <label
//                         htmlFor="file_input"
//                         className="block text-sm cursor-pointer hover:bg-[#00FF5730] dark:text-gray-400 focus:outline-none px-4 py-2 rounded-md"
//                         style={{
//                           border: '0.5px solid #187800',
//                           borderRadius: '10px',
//                           maxWidth: uploadedFileName ? `${uploadedFileName.length * 9}px` : '200px',
//                           height: '40px'
//                         }}
//                       >
//                         {uploadedFileName ? uploadedFileName : 'Upload Job Description'}
//                       </label>
//                     </div>
//                     <div className="items-center mt-2 sm:mt-0">
//                       <button
//                         onClick={startQaSession}
//                         className="block text-sm cursor-pointer hover:bg-[#00FF5730] dark:text-gray-400 focus:outline-none px-4 py-2 rounded-md"
//                         style={{
//                           border: '0.5px solid #187800',
//                           borderRadius: '10px',
//                           maxWidth: '290px',
//                           height: '40px'
//                         }}
//                       >
//                         Start Q&A for requirement gathering
//                       </button>
//                     </div>
//                   </div>
//                   {isUploading && (
//                     <div className="mt-2">
//                       {/* <img className="w-8 h-8" src="images/loading.gif" alt='Loading...' /> */}
//                     </div>
//                   )}
//                 </div>
//               )}

//               {containsKeywordSummary && !summaryResponseGiven && (
//                 <div className="flex justify-center gap-2 mt-2 mb-2">
//                   <button
//                     onClick={() => handleSummaryResponse(true)}
//                     className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded button-active"
//                   >
//                     Agree
//                   </button>
//                   <button
//                     onClick={() => handleSummaryResponse(false)}
//                     className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded  button-active"
//                   >
//                     Disagree
//                   </button>
//                 </div>
//               )}

//               {isMoveToPayment && (
//                 <div className="flex justify-center gap-2 mt-2">
//                   <button
//                     onClick={handleNavigate}
//                     className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded  button-active"
//                   >
//                     Move to Payment Structure
//                   </button>
//                 </div>
//               )}
//             </React.Fragment>
//           )}
//         </React.Fragment>
//       );
//     });
//   };

//   const loadingOverlay = isLoading ? (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//       <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-400"></div>
//     </div>
//   ) : null;

//   return (
//     <>
//       {loadingOverlay}
//       <div className={`left-12 w-full text-white sm:fixed sm:left-1/5 md:left-1/4 sm:w-3/5  h-full bg-[#001100]  items-center ${isLoading ? 'blur-sm' : ''}`}>

//         <div className="flex h-[5%] mt-8">
//           <select className="bg-[#99999929] border-none hover:border-[#00FF47] border-2 text-white text-sm px-4  rounded-lg">
//             <option value="3.0" style={{ backgroundColor: 'black' }}>HireXtra 3.0</option>
//             <option value="4.0" style={{ backgroundColor: 'black' }}>HireXtra 4.0</option>
//             <option value="5.0" style={{ backgroundColor: 'black' }}>HireXtra 5.0</option>
//           </select>
//         </div>

//         <h1 className='text-xl text-bold text-gray-1000 mt-4'> HireXtra Hiring assistant </h1>

//         <div className="flex flex-col mt-4 h-[60%] overflow-y-auto"
//           style={{
//             scrollbarWidth: 'none',
//             msOverflowStyle: 'none',
//             '&::-webkit-scrollbar': {
//               display: 'none',
//             },
//           }}
//         >
//           {renderMessages()}
//           <div ref={messagesEndRef} />
//         </div>
//         <div className="left-12 w-4/5 bottom-2 sm:fixed sm:left-1/4 md:left-1/4 sm:w-3/5 sm:bottom-8 sm:w-3/5 bg-[#001100] border-[#00FF47] flex items-center">
//           <textarea
//             placeholder="Message HireXtra"
//             className="bg-transparent border-[#00FF47] border-2 text-white px-4 py-2 flex-1 rounded-xl resize-none overflow-hidden"  // Adjusting classes for design
//             value={userInput}
//             disabled={isSending || isLoading}
//             onChange={handleInputChange}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter' && !e.shiftKey) {
//                 e.preventDefault();
//                 handleSubmit();
//               }
//             }}
//             style={{
//               borderColor: '#00FF47',
//               maxHeight: '200px',  // Corrected as max height
//               outline: 'none',
//               padding: '4px 35px 4px 20px',  // Increased right padding to create a gap by the scrollbar
//             }}
//             onFocus={(e) => e.target.style.borderColor = '#00FF47'}
//             onBlur={(e) => e.target.style.borderColor = '#00FF47'}
//             onInput={adjustTextareaHeight}
//             ref={inputRef}
//           />
//           <button
//             className="absolute right-29 h-10 sm:right-7 bg-[#00FF382B] border-green-500 border-1 px-1 text-white rounded-md"  // Increased right positioning to create gap
//             disabled={isSending || isLoading}
//             onClick={handleSubmit}
//           >
//             {isSending ? (
//               <img className="h-8 w-8" src='/images/Stop.png' alt='Loading' />
//             ) : (
//               <img className="px-2 py-2" src='/images/Vector.png' alt='Send' />
//             )}
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Chat;

// finger print passed to evrey message

// import React, { useState, useRef, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
// import gfm from 'remark-gfm';
// import getFingerprint from './browserFingerprint';

// const LoadingAnimation = () => (
//   <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//     <div className="relative w-40 h-40">
//       <div className="absolute inset-0 animate-spin">
//         <div className="h-full w-full rounded-full border-t-4 border-b-4 border-green-400"></div>
//       </div>
//       <div className="absolute inset-0 animate-spin" style={{animationDuration: '3s'}}>
//         <div className="h-full w-full rounded-full border-l-4 border-r-4 border-green-800 rotate-45"></div>
//       </div>
//       <div className="absolute inset-0 animate-pulse">
//         <div className="h-full w-full rounded-full bg-purple-400 opacity-30"></div>
//       </div>
//       <div className="absolute inset-0 flex justify-center items-center">
//         <div className="text-white text-2xl font-bold animate-bounce">
//           Loading...
//         </div>
//       </div>
//     </div>
//   </div>
// );

// const Chat = ({ isOpen, onRequirementIdExtracted }) => {
//   const [messages, setMessages] = useState([{ text: "Hi, I am HireXtra. How can I help you today?", isUser: false }]);
//   const [userInput, setUserInput] = useState('');
//   const messagesEndRef = useRef(null);
//   const inputRef = useRef(null);
//   const [uploadedFileName, setUploadedFileName] = useState('');
//   const [qaSessionStarted, setQaSessionStarted] = useState(false);
//   const [jobDescriptionUploaded, setJobDescriptionUploaded] = useState(false);
//   const [summaryResponseGiven, setSummaryResponseGiven] = useState(false);
//   const [isSending, setIsSending] = useState(false);
//   const [isUploading, setIsUploading] = useState(false);
//   const [fingerprint, setFingerprint] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [renderedMessages, setRenderedMessages] = useState(new Set());
//   const navigate = useNavigate();
//   const fingerprintGenerated = useRef(false);
//   const wsRef = useRef(null);

//   useEffect(() => {
//     scrollToBottom();
//   }, [messages]);

//   useEffect(() => {
//     if (inputRef.current) {
//       inputRef.current.focus();
//     }
//   }, [messages]);

//   useEffect(() => {
//     const generateFingerprint = async () => {
//       if (!fingerprintGenerated.current) {
//         const fp = await getFingerprint();
//         setFingerprint(fp);
//         console.log('Window Fingerprint:', fp);
//         fingerprintGenerated.current = true;
//       }
//     };

//     generateFingerprint();
//   }, []);

//   useEffect(() => {
//     if (wsRef.current) {
//       wsRef.current.close();
//     }

//     wsRef.current = new WebSocket('ws://air.talanton.ai:5000/stream');

//     wsRef.current.onmessage = (event) => {
//       // console.log("websocket message:" , event.data);
//       const newMessage = event.data.replace("Data received as: ", "").trim();
//       setMessages((prevMessages) => {
//         const lastMessage = prevMessages[prevMessages.length - 1];
//         console.log(prevMessages);
//         if (!lastMessage.isUser) {
//           const updatedMessage = { ...lastMessage, text: newMessage };
//           return [...prevMessages.slice(0, -1), updatedMessage];
//         } else {
//           if (newMessage.includes("Job Requirement ID:") && renderedMessages.has(newMessage)) {
//             return prevMessages;
//           } else if (newMessage.includes("could you please specify the industry this position is associated with") && renderedMessages.has(newMessage)) {
//             return prevMessages;
//           } else {
//             setRenderedMessages((prevRendered) => new Set(prevRendered).add(newMessage));
//             return [...prevMessages, { text: newMessage, isUser: false }];
//           }
//         }
//       });
//     };

//     wsRef.current.onclose = () => {
//       console.log('WebSocket connection closed');
//     };
//     wsRef.current.onopen = () => {
//       console.log('WebSocket connection eastablished');
//     };

//     return () => {
//       if (wsRef.current) {
//         wsRef.current.close();
//       }
//     };
//   }, [renderedMessages]);

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };

//   const handleInputChange = (e) => {
//     setUserInput(e.target.value);
//   };

//   const handleNavigate = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://air.talanton.ai:8000', {
//         content: "move to payment",
//         session_token: fingerprint
//       });
//       const priceData = response.data;
//       navigate('/paymentstructure', { state: { priceData } });
//     } catch (error) {
//       console.error('Error moving to payment structure:', error);
//     }
//     setIsLoading(false);
//   };

//   const handleFileChange = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setIsUploading(true);
//       setIsLoading(true);
//       setUploadedFileName(file.name);
//       const formData = new FormData();
//       formData.append('uploaded_file', file);

//       const uploadResponse = await axios.post('http://air.talanton.ai:8000/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });

//       const response = await axios.post('http://air.talanton.ai:8000', {
//         content: `upload jd ${uploadResponse.data}`,
//         session_token: fingerprint
//       });

//       const hirexMessage = {
//         text: response.data.message,
//         isUser: false
//       };

//       setMessages(prevMessages => [...prevMessages, hirexMessage]);
//       setJobDescriptionUploaded(true);
//       setIsUploading(false);
//       setIsLoading(false);
//     }
//   };

//   const startQaSession = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://air.talanton.ai:8000', {
//         content: "Start Q&A",
//         session_token: fingerprint
//       });

//       const hirexMessage = {
//         text: response.data.message,
//         isUser: false
//       };
//       setMessages(prevMessages => {
//         if (renderedMessages.has(hirexMessage.text)) {
//           return prevMessages;
//         } else {
//           setRenderedMessages((prevRendered) => new Set(prevRendered).add(hirexMessage.text));
//           return [...prevMessages, hirexMessage];
//         }
//       });
//       setQaSessionStarted(true);
//     } catch (error) {
//       console.error('Error starting Q&A session:', error);
//     }
//     setIsLoading(false);
//   };

//   const handleSubmit = async () => {
//     setUserInput('');
//     if (!isSending && userInput.trim() !== '') {
//       setIsSending(true);
//       const userMessage = { text: userInput, isUser: true };
//       setMessages(prevMessages => [...prevMessages, userMessage]);

//       try {
//         await axios.post('http://air.talanton.ai:8000', {
//           content: userInput,
//           session_token: fingerprint
//         });

//         setIsSending(false);
//       } catch (error) {
//         console.error('Error sending message:', error);
//         setIsSending(false);
//       }
//     }
//   };

//   const handleSummaryResponse = async (agreed) => {
//     const responseText = agreed ? "It's perfect" : "It's not perfect";
//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://air.talanton.ai:8000', {
//         content: responseText,
//         session_token: fingerprint
//       });

//       const message = {
//         text: response.data.message,
//         isUser: false
//       };

//       setMessages(prevMessages => {
//         if (renderedMessages.has(message.text)) {
//           return prevMessages;
//         } else {
//           setRenderedMessages((prevRendered) => new Set(prevRendered).add(message.text));
//           return [...prevMessages, message];
//         }
//       });

//       if (response.data.message.includes("Job Requirement ID:")) {
//         const extractedId = response.data.message.split("Job Requirement ID:")[1].split('\n')[0].trim();
//         onRequirementIdExtracted(extractedId);
//       }
//       setSummaryResponseGiven(true);
//     } catch (error) {
//       console.error('Error handling summary response:', error);
//     }
//     setIsLoading(false);
//   };

//   const adjustTextareaHeight = (e) => {
//     const textarea = e.target;
//     textarea.style.height = '50px';
//     textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`;
//     if (textarea.scrollHeight > textarea.offsetHeight) {
//       textarea.style.overflowY = 'scroll';
//     } else {
//       textarea.style.overflowY = 'hidden';
//     }
//   };

//   const renderMessages = () => {
//     return messages.map((message, index) => {
//       const lowercaseMessage = (message.text || '').toLowerCase();
//       const keywords = ["upload your job description"];
//       const needsJobDescription = keywords.some(keyword => lowercaseMessage.includes(keyword));
//       const containsKeywordSummary = lowercaseMessage.includes('summary of the hiring requirements');
//       const isMoveToPayment = lowercaseMessage.includes('move to payment');

//       return (
//         <React.Fragment key={index}>
//           {message.isUser ? (
//             <div className="flex justify-end mb-4 mr-1/5">
//               <div className="bg-[#D9D9D921] text-white text-sm px-6 py-2 rounded-3xl ml-4 max-w-[80%]">
//                 {message.text}
//               </div>
//               <img src="/images/Ellipse 13.png" alt="User" className="w-10 h-10 rounded-full ml-2" />
//             </div>
//           ) : (
//             <React.Fragment>
//               <div className="flex justify-start mb-4">
//                 <img src="/images/image 23.png" alt="Hirex" className="w-10 h-10 rounded-full mr-2" />
//                 <div className="bg-[#D9D9D921] text-white text-sm px-6 py-2 rounded-3xl mr-4 max-w-[80%]">
//                   <ReactMarkdown remarkPlugins={[gfm]}>{message.text}</ReactMarkdown>
//                 </div>
//               </div>

//               {needsJobDescription && !jobDescriptionUploaded && !qaSessionStarted && (
//                 <div className='ml-12 sm:flex sm:flex-col sm:items-center sm:gap-2 mb-4'>
//                   <div className="sm:flex sm:gap-2">
//                     <div className="items-center">
//                       <input
//                         className="hidden"
//                         type="file"
//                         id="file_input"
//                         onChange={handleFileChange}
//                       />
//                       <label
//                         htmlFor="file_input"
//                         className="block text-sm cursor-pointer hover:bg-[#00FF5730] dark:text-gray-400 focus:outline-none px-4 py-2 rounded-md"
//                         style={{
//                           border: '0.5px solid #187800',
//                           borderRadius: '10px',
//                           maxWidth: uploadedFileName ? `${uploadedFileName.length * 9}px` : '200px',
//                           height: '40px'
//                         }}
//                       >
//                         {uploadedFileName ? uploadedFileName : 'Upload Job Description'}
//                       </label>
//                     </div>
//                     <div className="items-center mt-2 sm:mt-0">
//                       <button
//                         onClick={startQaSession}
//                         className="block text-sm cursor-pointer hover:bg-[#00FF5730] dark:text-gray-400 focus:outline-none px-4 py-2 rounded-md"
//                         style={{
//                           border: '0.5px solid #187800',
//                           borderRadius: '10px',
//                           maxWidth: '290px',
//                           height: '40px'
//                         }}
//                       >
//                         Start Q&A for requirement gathering
//                       </button>
//                     </div>
//                   </div>
//                   {isUploading && (
//                     <div className="mt-2">
//                       {/* <img className="w-8 h-8" src="images/loading.gif" alt='Loading...' /> */}
//                     </div>
//                   )}
//                 </div>
//               )}

//               {containsKeywordSummary && !summaryResponseGiven && (
//                 <div className="flex justify-center gap-2 mt-2 mb-2">
//                   <button
//                     onClick={() => handleSummaryResponse(true)}
//                     className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded button-active"
//                   >
//                     Agree
//                   </button>
//                   <button
//                     onClick={() => handleSummaryResponse(false)}
//                     className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded  button-active"
//                   >
//                     Disagree
//                   </button>
//                 </div>
//               )}

//               {isMoveToPayment && (
//                 <div className="flex justify-center gap-2 mt-2">
//                   <button
//                     onClick={handleNavigate}
//                     className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded  button-active"
//                   >
//                     Move to Payment Structure
//                   </button>
//                 </div>
//               )}
//             </React.Fragment>
//           )}
//         </React.Fragment>
//       );
//     });
//   };

//   const loadingOverlay = isLoading ? <LoadingAnimation /> : null;

//   return (
//     <>
//       {loadingOverlay}
//       <div className={`left-12 w-full text-white sm:fixed sm:left-1/5 md:left-1/4 sm:w-3/5  h-full bg-[#001100]  items-center ${isLoading ? 'blur-sm' : ''}`}>

//         <div className="flex h-[5%] mt-8">
//           <select className="bg-[#99999929] border-none hover:border-[#00FF47] border-2 text-white text-sm px-4  rounded-lg">
//             <option value="3.0" style={{ backgroundColor: 'black' }}>HireXtra 3.0</option>
//             <option value="4.0" style={{ backgroundColor: 'black' }}>HireXtra 4.0</option>
//             <option value="5.0" style={{ backgroundColor: 'black' }}>HireXtra 5.0</option>
//           </select>
//         </div>

//         <h1 className='text-xl text-bold text-gray-1000 mt-4'> HireXtra Hiring assistant </h1>

//         <div className="flex flex-col mt-4 h-[60%] overflow-y-auto"
//           style={{
//             scrollbarWidth: 'none',
//             msOverflowStyle: 'none',
//             '&::-webkit-scrollbar': {
//               display: 'none',
//             },
//           }}
//         >
//           {renderMessages()}
//           <div ref={messagesEndRef} />
//         </div>
//         <div className="left-12 w-4/5 bottom-2 sm:fixed sm:left-1/4 md:left-1/4 sm:w-3/5 sm:bottom-8 sm:w-3/5 bg-[#001100] border-[#00FF47] flex items-center">
//           <textarea
//             placeholder="Message HireXtra"
//             className="bg-transparent border-[#00FF47] border-2 text-white px-4 py-2 flex-1 rounded-xl resize-none overflow-hidden"
//             value={userInput}
//             disabled={isSending || isLoading}
//             onChange={handleInputChange}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter' && !e.shiftKey) {
//                 e.preventDefault();
//                 handleSubmit();
//               }
//             }}
//             style={{
//               borderColor: '#00FF47',
//               maxHeight: '200px',
//               outline: 'none',
//               padding: '4px 35px 4px 20px',
//             }}
//             onFocus={(e) => e.target.style.borderColor = '#00FF47'}
//             onBlur={(e) => e.target.style.borderColor = '#00FF47'}
//             onInput={adjustTextareaHeight}
//             ref={inputRef}
//           />
//           <button
//             className="absolute right-29 h-10 sm:right-7 bg-[#00FF382B] border-green-500 border-1 px-1 text-white rounded-md"
//             disabled={isSending || isLoading}
//             onClick={handleSubmit}
//           >
//             {isSending ? (
//               <img className="h-8 w-8" src='/images/Stop.png' alt='Loading' />
//             ) : (
//               <img className="px-2 py-2" src='/images/Vector.png' alt='Send' />
//             )}
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Chat;

// finger print generted before and passed as a query parameter in the websocket url

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import getFingerprint from "./browserFingerprint";

const LoadingAnimation = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="relative w-40 h-40">
      <div className="absolute inset-0 animate-spin">
        <div className="h-full w-full rounded-full border-t-4 border-b-4 border-green-400"></div>
      </div>
      <div
        className="absolute inset-0 animate-spin"
        style={{ animationDuration: "3s" }}
      >
        <div className="h-full w-full rounded-full border-l-4 border-r-4 border-green-800 rotate-45"></div>
      </div>
      <div className="absolute inset-0 animate-pulse">
        <div className="h-full w-full rounded-full bg-purple-400 opacity-30"></div>
      </div>
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="text-white text-2xl font-bold animate-bounce">
          Loading...
        </div>
      </div>
    </div>
  </div>
);

const Chat = ({ isOpen, onRequirementIdExtracted }) => {
  const [messages, setMessages] = useState([
    { text: "Hi, I am HireXtra. How can I help you today?", isUser: false },
  ]);
  const [userInput, setUserInput] = useState("");
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [qaSessionStarted, setQaSessionStarted] = useState(false);
  const [jobDescriptionUploaded, setJobDescriptionUploaded] = useState(false);
  const [summaryResponseGiven, setSummaryResponseGiven] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fingerprint, setFingerprint] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [renderedMessages, setRenderedMessages] = useState(new Set());
  const navigate = useNavigate();
  const fingerprintGenerated = useRef(false);
  const wsRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [messages]);

  useEffect(() => {
    const generateFingerprint = async () => {
      if (!fingerprintGenerated.current) {
        const fp = await getFingerprint();
        setFingerprint(fp);
        console.log("Window Fingerprint:", fp);
        fingerprintGenerated.current = true;
      }
    };

    generateFingerprint();
  }, []);

  useEffect(() => {
    if (fingerprint && !wsRef.current) {
      wsRef.current = new WebSocket(
        `ws://air.talanton.ai:5000/stream?session_token=${fingerprint}`
      );

      wsRef.current.onmessage = (event) => {
        const newMessage = event.data.replace("Data received as: ", "").trim();
        setMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (!lastMessage.isUser) {
            const updatedMessage = { ...lastMessage, text: newMessage };
            return [...prevMessages.slice(0, -1), updatedMessage];
          } else {
            if (
              newMessage.includes("Job Requirement ID:") &&
              renderedMessages.has(newMessage)
            ) {
              return prevMessages;
            } else if (
              newMessage.includes(
                "could you please specify the industry this position is associated with"
              ) &&
              renderedMessages.has(newMessage)
            ) {
              return prevMessages;
            } else {
              setRenderedMessages((prevRendered) =>
                new Set(prevRendered).add(newMessage)
              );
              return [...prevMessages, { text: newMessage, isUser: false }];
            }
          }
        });
      };

      wsRef.current.onclose = () => {
        console.log("WebSocket connection closed");
      };
      wsRef.current.onopen = () => {
        console.log("WebSocket connection established");
      };
    }

    return () => {
      // if (wsRef.current) {
      //   wsRef.current.close();
      // }
    };
  }, [fingerprint, renderedMessages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleNavigate = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("http://air.talanton.ai:8000", {
        content: "move to payment",
        session_token: fingerprint,
      });
      const priceData = response.data;
      navigate("/paymentstructure", { state: { priceData } });
    } catch (error) {
      console.error("Error moving to payment structure:", error);
    }
    setIsLoading(false);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploading(true);
      setIsLoading(true);
      setUploadedFileName(file.name);
      const formData = new FormData();
      formData.append("uploaded_file", file);

      const uploadResponse = await axios.post(
        "http://air.talanton.ai:8000/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const response = await axios.post("http://air.talanton.ai:8000", {
        content: `upload jd ${uploadResponse.data}`,
        session_token: fingerprint,
      });

      const hirexMessage = {
        text: response.data.message,
        isUser: false,
      };

      setMessages((prevMessages) => [...prevMessages, hirexMessage]);
      setJobDescriptionUploaded(true);
      setIsUploading(false);
      setIsLoading(false);
    }
  };

  const startQaSession = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("http://air.talanton.ai:8000", {
        content: "Start Q&A",
        session_token: fingerprint,
      });

      const hirexMessage = {
        text: response.data.message,
        isUser: false,
      };
      setMessages((prevMessages) => {
        if (renderedMessages.has(hirexMessage.text)) {
          return prevMessages;
        } else {
          setRenderedMessages((prevRendered) =>
            new Set(prevRendered).add(hirexMessage.text)
          );
          return [...prevMessages, hirexMessage];
        }
      });
      setQaSessionStarted(true);
    } catch (error) {
      console.error("Error starting Q&A session:", error);
    }
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setUserInput("");
    if (!isSending && userInput.trim() !== "") {
      setIsSending(true);
      const userMessage = { text: userInput, isUser: true };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      try {
        await axios.post("http://air.talanton.ai:8000", {
          content: userInput,
          session_token: fingerprint,
        });

        setIsSending(false);
      } catch (error) {
        console.error("Error sending message:", error);
        setIsSending(false);
      }
    }
  };

  const handleSummaryResponse = async (agreed) => {
    const responseText = agreed ? "It's perfect" : "It's not perfect";
    setIsLoading(true);
    try {
      const response = await axios.post("http://air.talanton.ai:8000", {
        content: responseText,
        session_token: fingerprint,
      });

      const message = {
        text: response.data.message,
        isUser: false,
      };

      setMessages((prevMessages) => {
        if (renderedMessages.has(message.text)) {
          return prevMessages;
        } else {
          setRenderedMessages((prevRendered) =>
            new Set(prevRendered).add(message.text)
          );
          return [...prevMessages, message];
        }
      });

      if (response.data.message.includes("Job Requirement ID:")) {
        const extractedId = response.data.message
          .split("Job Requirement ID:")[1]
          .split("\n")[0]
          .trim();
        onRequirementIdExtracted(extractedId);
      }
      setSummaryResponseGiven(true);
    } catch (error) {
      console.error("Error handling summary response:", error);
    }
    setIsLoading(false);
  };

  const adjustTextareaHeight = (e) => {
    const textarea = e.target;
    textarea.style.height = "50px";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`;
    if (textarea.scrollHeight > textarea.offsetHeight) {
      textarea.style.overflowY = "scroll";
    } else {
      textarea.style.overflowY = "hidden";
    }
  };

  const renderMessages = () => {
    return messages.map((message, index) => {
      const lowercaseMessage = (message.text || "").toLowerCase();
      const keywords = ["upload your job description"];
      const needsJobDescription = keywords.some((keyword) =>
        lowercaseMessage.includes(keyword)
      );
      const containsKeywordSummary = lowercaseMessage.includes(
        "summary of the hiring requirements"
      );
      const isMoveToPayment = lowercaseMessage.includes("move to payment");

      return (
        <React.Fragment key={index}>
          {message.isUser ? (
            <div className="flex justify-end mb-4 mr-1/5">
              <div className="bg-[#D9D9D921] text-white text-sm px-6 py-2 rounded-3xl ml-4 max-w-[80%]">
                {message.text}
              </div>
              <img
                src="/images/Ellipse 13.png"
                alt="User"
                className="w-10 h-10 rounded-full ml-2"
              />
            </div>
          ) : (
            <React.Fragment>
              <div className="flex justify-start mb-4">
                <img
                  src="/images/image 23.png"
                  alt="Hirex"
                  className="w-10 h-10 rounded-full mr-2"
                />
                <div className="bg-[#D9D9D921] text-white text-sm px-6 py-2 rounded-3xl mr-4 max-w-[80%]">
                  <ReactMarkdown remarkPlugins={[gfm]}>
                    {message.text}
                  </ReactMarkdown>
                </div>
              </div>

              {needsJobDescription &&
                !jobDescriptionUploaded &&
                !qaSessionStarted && (
                  <div className="ml-12 sm:flex sm:flex-col sm:items-center sm:gap-2 mb-4">
                    <div className="sm:flex sm:gap-2">
                      <div className="items-center">
                        <input
                          className="hidden"
                          type="file"
                          id="file_input"
                          onChange={handleFileChange}
                        />
                        <label
                          htmlFor="file_input"
                          className="block text-sm cursor-pointer hover:bg-[#00FF5730] dark:text-gray-400 focus:outline-none px-4 py-2 rounded-md"
                          style={{
                            border: "0.5px solid #187800",
                            borderRadius: "10px",
                            maxWidth: uploadedFileName
                              ? `${uploadedFileName.length * 9}px`
                              : "200px",
                            height: "40px",
                          }}
                        >
                          {uploadedFileName
                            ? uploadedFileName
                            : "Upload Job Description"}
                        </label>
                      </div>
                      <div className="items-center mt-2 sm:mt-0">
                        <button
                          onClick={startQaSession}
                          className="block text-sm cursor-pointer hover:bg-[#00FF5730] dark:text-gray-400 focus:outline-none px-4 py-2 rounded-md"
                          style={{
                            border: "0.5px solid #187800",
                            borderRadius: "10px",
                            maxWidth: "290px",
                            height: "40px",
                          }}
                        >
                          Start Q&A for requirement gathering
                        </button>
                      </div>
                    </div>
                    {isUploading && (
                      <div className="mt-2">
                        {/* <img className="w-8 h-8" src="images/loading.gif" alt='Loading...' /> */}
                      </div>
                    )}
                  </div>
                )}

              {containsKeywordSummary && !summaryResponseGiven && (
                <div className="flex justify-center gap-2 mt-2 mb-2">
                  <button
                    onClick={() => handleSummaryResponse(true)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded button-active"
                  >
                    Agree
                  </button>
                  <button
                    onClick={() => handleSummaryResponse(false)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded  button-active"
                  >
                    Disagree
                  </button>
                </div>
              )}

              {isMoveToPayment && (
                <div className="flex justify-center gap-2 mt-2">
                  <button
                    onClick={handleNavigate}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded  button-active"
                  >
                    Move to Payment Structure
                  </button>
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    });
  };

  const loadingOverlay = isLoading ? <LoadingAnimation /> : null;

  return (
    <>
      {loadingOverlay}
      <div
        className={`left-12 w-full text-white sm:fixed sm:left-1/5 md:left-1/4 sm:w-3/5  h-full bg-[#001100]  items-center ${
          isLoading ? "blur-sm" : ""
        }`}
      >
        <div className="flex h-[5%] mt-8">
          <select className="bg-[#99999929] border-none hover:border-[#00FF47] border-2 text-white text-sm px-4  rounded-lg">
            <option value="3.0" style={{ backgroundColor: "black" }}>
              HireXtra 3.0
            </option>
            <option value="4.0" style={{ backgroundColor: "black" }}>
              HireXtra 4.0
            </option>
            <option value="5.0" style={{ backgroundColor: "black" }}>
              HireXtra 5.0
            </option>
          </select>
        </div>

        <h1 className="text-xl text-bold text-gray-1000 mt-4">
          {" "}
          HireXtra Hiring assistant{" "}
        </h1>

        <div
          className="flex flex-col mt-4 h-[60%] overflow-y-auto"
          style={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {renderMessages()}
          <div ref={messagesEndRef} />
        </div>
        <div className="left-12 w-4/5 bottom-2 sm:fixed sm:left-1/4 md:left-1/4 sm:w-3/5 sm:bottom-8 sm:w-3/5 bg-[#001100] border-[#00FF47] flex items-center">
          <textarea
            placeholder="Message HireXtra"
            className="bg-transparent border-[#00FF47] border-2 text-white px-4 py-2 flex-1 rounded-xl resize-none overflow-hidden"
            value={userInput}
            disabled={isSending || isLoading}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit();
              }
            }}
            style={{
              borderColor: "#00FF47",
              maxHeight: "200px",
              outline: "none",
              padding: "4px 35px 4px 20px",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#00FF47")}
            onBlur={(e) => (e.target.style.borderColor = "#00FF47")}
            onInput={adjustTextareaHeight}
            ref={inputRef}
          />
          <button
            className="absolute right-29 h-10 sm:right-7 bg-[#00FF382B] border-green-500 border-1 px-1 text-white rounded-md"
            disabled={isSending || isLoading}
            onClick={handleSubmit}
          >
            {isSending ? (
              <img className="h-8 w-8" src="/images/Stop.png" alt="Loading" />
            ) : (
              <img className="px-2 py-2" src="/images/Vector.png" alt="Send" />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Chat;
