import React from 'react'

export default function Subscribe() {
  return (
    <div className=' bg-[#001100]'>
    <div className="mt-18 w-full sm:fixed h-full sm:left-1/4 md:left-1/4 sm:w-[50%]  bg-[#001100] items-center flex flex-col justify-center">
      <div className="w-full flex justify-center">
        <img src="images/image 23.png" alt="img" />
      </div>
  
      <div className="text-center text-white text-4xl text-bold py-4">Subscribe to get the<br/> full experience</div>
      <button className="bg-green-500 text-white px-4 py-2 rounded-lg">Subscribe</button>
    </div>
  </div>
  
  )
}
