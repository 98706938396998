import Fingerprint2 from 'fingerprintjs2';

const getFingerprint = () => {
  return new Promise((resolve) => {
    // Check if we already have a window fingerprint
    let windowFingerprint = sessionStorage.getItem('windowFingerprint');
    
    if (windowFingerprint) {
      resolve(windowFingerprint);
    } else {
      // If not, generate a new one
      Fingerprint2.get((components) => {
        const values = components.map(component => component.value);
        // Add a random component to ensure uniqueness per window
        values.push(Math.random().toString(36).substring(2));
        const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
        
        // Store the fingerprint in sessionStorage
        sessionStorage.setItem('windowFingerprint', fingerprint);
        
        resolve(fingerprint);
      });
    }
  });
};

export default getFingerprint;