import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import axios from 'axios';
export default function PaymentStructure() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const priceData = location.state?.priceData;
  // const handleCheckout = async () => {
  //   try {
  //     console.log("price ", priceData['Total Price with GST']);
  //     const response = await axios.post('http://air.talanton.ai:7000/create-checkout-session', {
  //         total_price: Math.round(priceData['Total Price'] * 100)
  //     });
  //     console.log("response: ", response);
  //     const url = response.data; // Update this line
  //     window.location.href = url; //
  //   } catch (error) {
  //     console.error('Error creating checkout session:', error);
  //   }
  // };
  const handleCheckout = () => {
    navigate("/workflow");
  };
  useEffect(() => {
    const success = searchParams.get("success");
    const cancel = searchParams.get("cancel");
    if (success) {
      navigate("/workflow"); // Navigate to the workflow page on successful payment
    } else if (cancel) {
      alert("Payment was cancelled."); // Show an alert if payment was cancelled
    }
  }, [searchParams, navigate]);
  return (
    <>
      <div className="left-12 w-full sm:fixed text-white sm:left-1/4 md:left-1/4 sm:w-3/5 h-full bg-[#001100] items-center">
        <div className="flex h-[5%] mt-8 ml-1/4">
          <select className="bg-[#99999929] border-none hover:border-[#00FF47] border-2 text-sm text-white px-4  rounded-lg">
            <option value="3.0">HireXtra 3.0</option>
            <option value="4.0">HireXtra 4.0</option>
            <option value="5.0">HireXtra 5.0</option>
          </select>
        </div>
        <div className="text-white">
          <h1 className="text-white text-bold mt-4 text-xl">
            Payment Structure
          </h1>
          {priceData && (
            <div className="text-white text-lg mt-10">
              <table className="table-auto">
                <tbody>
                  <tr>
                    <td colSpan="2" className="border-b-2 border-[#4A4A4A]">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td className="px-16 py-6">Total Price</td>
                    <td className="px-16 py-6">$ {priceData["Total Price"]}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div className="mt-8 w-[50%]">
            <div className="bg-[#D9D9D917] rounded-xl h-32 ">
              <h1 className="text-center justify-center items-center py-4 text-lg px-2">
                We offer a 100% refund within 14 days from the date of the last
                resource's rejection by the client.
              </h1>
            </div>
          </div>
        </div>
        <div className="mt-4 items-center text-bold">
          <button
            className="bg-green-500 px-4 py-2 text-bold rounded-lg"
            onClick={handleCheckout}
          >
            Checkout
          </button>
        </div>
      </div>
    </>
  );
}
