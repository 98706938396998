import React, { useEffect, useState } from "react";

function ChatComponent() {
  const [latestMessage, setLatestMessage] = useState(null);

  useEffect(() => {
    // Create a WebSocket connection
    const socket = new WebSocket("ws://air.talanton.ai:8000/ws");

    // Event listener for incoming messages
    socket.onmessage = (event) => {
      // Assuming the received data is a string
      const message = event.data;
      setLatestMessage(message);
    };

    // Cleanup function to close the WebSocket connection
    return () => {
      socket.close();
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div className="text-white mt-24">
      <h1 className="text-bold text-5xl">Chat</h1>
      <div className="py-8">
        {latestMessage && (
          <div>
            <p>{latestMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatComponent;
