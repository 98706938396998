import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PaymentSuccess() {
  const navigate = useNavigate();

  const handleDone = () => {
    navigate('/workflow');
  };

  return (
    <div>
      <div className=' bg-[#001100]'>
        <div className="mt-18 w-full sm:fixed h-full sm:left-1/4 md:left-1/4 sm:w-[50%]  bg-[#001100] items-center flex flex-col justify-center">
          <div className="w-full flex justify-center">
            <div style={{ position: 'relative' }}>
              <img src="images/image%2023.png" alt="img" style={{ width: '100%', height: 'auto' }} />
              <img
                src="images/Vector%20(2).png"
                alt="background-img"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: 'auto',
                  zIndex: -1,
                }}
              />
            </div>
          </div>

          <div className="text-center text-white text-2xl text-bold py-4">
            Your payment is done successfully. <br/> Now you can experience the full HireXtra version
          </div>
          <button className="bg-green-500 text-white px-4 py-2 rounded-lg" onClick={handleDone}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}
