// // import React, { useState, useEffect } from 'react';
// // import CandidateProfileCard from './CandidateProfileCard';

// // const CandidatesPage = () => {
// //   const [candidateData, setCandidateData] = useState([]);

// //   useEffect(() => {
// //     const socket = new WebSocket('ws://air.talanton.ai:8000');

// //     socket.addEventListener('message', (event) => {
// //       const message = event.data;
// //       try {
// //         if (message.startsWith('Candidate JSON :')) {
// //           const candidateJson = JSON.parse(message.replace('Candidate JSON :', ''));
// //           setCandidateData((prevData) => [...prevData, candidateJson]);
// //         }
// //       } catch (error) {
// //         console.error('Failed to parse candidate JSON:', error);
// //       }
// //     });

// //     // Cleanup function
// //     return () => {
// //       socket.close();
// //     };
// //   }, []);

// //   return (
// //     <div style={{ backgroundColor: '#f0f0f0', padding: '20px' }}>
// //       <h1 style={{ color: '#006400' }}>Best Candidates</h1>
// //       <div style={{ display: 'flex', flexWrap: 'wrap' }}>
// //         {candidateData.map((candidate, index) => (
// //           <CandidateProfileCard key={index} candidateData={candidate} />
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default CandidatesPage;

// // import React, { useState, useEffect } from 'react';
// // import CandidateProfileCard from './CandidateProfileCard';

// // const CandidatesPage = () => {
// //   const [candidateData, setCandidateData] = useState([]);

// //   useEffect(() => {
// //     // Set global background color when component mounts
// //     // document.body.style.backgroundColor = '#225731';

// //     const socket = new WebSocket('ws://air.talanton.ai:4000/ws2');
// //     socket.addEventListener('message', (event) => {
// //       const message = event.data;
// //       try {
// //         if (message.startsWith('Candidate JSON :')) {
// //           const candidateJson = JSON.parse(message.replace('Candidate JSON :', ''));
// //           setCandidateData((prevData) => [...prevData, candidateJson]);
// //         }
// //       } catch (error) {
// //         console.error('Failed to parse candidate JSON:', error);
// //       }
// //     });

// //     // Cleanup function to reset styles and close socket
// //     return () => {
// //       //socket.close();
// //       //document.body.style.backgroundColor = ''; // Reset the background color
// //     };
// //   }, []);

// //   const styles = {
// //     container: {
// //       padding: '100px',
// //       display: 'flex',
// //       flexDirection: 'column',
// //       alignItems: 'center',
// //     },
// //     title: {
// //       color: 'rgba(255, 255, 255, 0.23)',
// //       marginBottom: '20px',
// //       alignItems: 'start',
// //       justifyContent: 'start'

// //     },
// //     cardContainer: {
// //       display: 'flex',
// //       flexWrap: 'wrap',
// //       justifyContent: 'center',
// //       maxWidth: '1200px', // Increased the maximum width
// //       gap: '20px', // Removed the gap between cards
// //     },
// //   };

// //   return (

// //     <div style={styles.container}>
// //       <h1 style={styles.title}>Best Candidates</h1>
// //       <div style={styles.cardContainer}>
// //         {candidateData.map((candidate, index) => (
// //           <CandidateProfileCard
// //             key={index}
// //             candidateData={candidate}
// //             style={{
// //               width: '520px',
// //               height: '599px',
// //               top: '165px',
// //               left: index % 2 === 0 ? '189px' : '786px', // Calculate left position based on index
// //               borderRadius: '10px 0px 0px 0px',
// //               opacity: '1', // Set opacity to 1 (visible)
// //             }}
// //           />
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default CandidatesPage;

// // import React, { useState, useEffect } from 'react';
// // import CandidateProfileCard from './CandidateProfileCard';

// // const CandidatesPage = () => {
// //   const [candidateData, setCandidateData] = useState([]);

// //   useEffect(() => {
// //     // Establish a WebSocket connection to receive candidate data
// //     const socket = new WebSocket('ws://air.talanton.ai:4000/ws2');

// //     socket.addEventListener('message', (event) => {
// //       const message = event.data;
// //       try {
// //         if (message.startsWith('Candidate JSON :')) {
// //           const jsonString = message.replace('Candidate JSON :', '');
// //           const candidateJson = JSON.parse(jsonString);
// //           if (candidateJson) {
// //             setCandidateData((prevData) => [...prevData, candidateJson]);
// //             // alert(candidateJson)
// //           }
// //         }
// //       } catch (error) {
// //         console.error('Failed to parse candidate JSON:', error);
// //       }
// //     });

// //     // Close the WebSocket connection on cleanup
// //     return () => {
// //       socket.close();
// //     };
// //   }, []);

// //   const styles = {
// //     container: {
// //       padding: '100px',
// //       display: 'flex',
// //       flexDirection: 'column',
// //       alignItems: 'center',
// //     },
// //     title: {
// //       color: 'rgba(255, 255, 255, 0.23)',
// //       marginBottom: '20px',
// //       alignItems: 'start',
// //       justifyContent: 'start',
// //     },
// //     cardContainer: {
// //       display: 'flex',
// //       flexWrap: 'wrap',
// //       justifyContent: 'center',
// //       maxWidth: '1200px',
// //       gap: '20px', // Adjust spacing between cards
// //     },
// //   };

// //   return (
// //     <div style={styles.container}>
// //       <h1 style={styles.title}>Best Candidates</h1>
// //       <div style={styles.cardContainer}>
// //         {candidateData.map((candidate, index) => (
// //           <CandidateProfileCard
// //             key={index}
// //             candidateData={candidate}
// //             style={{
// //               width: '520px',
// //               height: '599px',
// //               borderRadius: '10px 0px 0px 0px',
// //               opacity: '1',
// //             }}
// //           />
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default CandidatesPage;

// import React, { useState, useEffect } from 'react';
// import CandidateProfileCard from './CandidateProfileCard';

// const CandidatesPage = () => {
//   const [candidateData, setCandidateData] = useState([]);

//   useEffect(() => {
//     // Connect to the WebSocket server to receive candidate data
//     const socket = new WebSocket('ws://air.talanton.ai:4000/ws2');
//     socket.addEventListener('message', (event) => {
//       console.log('Connection Established')
//       const message = event.data;
//       try {
//         if (message.startsWith('Candidate JSON :')) {
//           const candidateJson = JSON.parse(message.replace('Candidate JSON :', ''));
//           setCandidateData((prevData) => [...prevData, candidateJson]);
//         }
//       } catch (error) {
//         console.error('Failed to parse candidate JSON:', error);
//       }
//     });

//     // Cleanup function to close the WebSocket connection
//     return () => {
//       socket.close();
//     };
//   }, []);

//   const styles = {
//     container: {
//       position: 'relative',
//       width: '100%',
//       height: '100vh',
//       paddingTop: '93px',
//       paddingLeft: '189px',
//       paddingRight: '189px',
//     },
//     title: {
//       position: 'absolute',
//       width: '229px',
//       height: '34px',
//       top: '93px',
//       left: '189px',
//       gap: '0px',
//       opacity: '1',
//       fontFamily: 'Inter',
//       fontSize: '28px',
//       fontWeight: 800,
//       lineHeight: '33.89px',
//       textAlign: 'left',
//       color: 'rgba(255, 255, 255, 0.23)',
//     },
//     cardContainer: {
//       position: 'relative',
//       marginTop: '140px', // Adjust to leave space for the title
//       display: 'grid',
//       gridTemplateColumns: '190px 600px', // Two columns starting from 190px and 790px
//       columnGap: '600px', // Distance between two columns is (790 - 190)px
//       rowGap: '60px', // Space between rows
//       justifyContent: 'start',
//       alignItems: 'start',
//     },
//     card: {
//       width: '520px',
//       height: '599px',
//       borderRadius: '10px 0px 0px 0px',
//       opacity: '1',
//     },
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.title}>Best Candidates</h1>
//       <div style={styles.cardContainer}>
//         {candidateData.map((candidate, index) => (
//           <CandidateProfileCard
//             key={index}
//             candidateData={candidate}
//             style={styles.card}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CandidatesPage;

// // import React, { useState, useEffect } from 'react';
// // import CandidateProfileCard from './CandidateProfileCard';

// // const CandidatesPage = () => {
// //   const [candidateData, setCandidateData] = useState(() => {
// //     // Attempt to load stored candidate data from localStorage on initialization
// //     const savedData = localStorage.getItem('candidates');
// //     return savedData ? JSON.parse(savedData) : [];
// //   });

// //   useEffect(() => {
// //     // Connect to the WebSocket server to receive candidate data
// //     const socket = new WebSocket('ws://air.talanton.ai:4000/ws2');
// //     socket.addEventListener('message', (event) => {
// //       console.log('Connection Established');
// //       const message = event.data;
// //       try {
// //         if (message.startsWith('Candidate JSON :')) {
// //           const candidateJson = JSON.parse(message.replace('Candidate JSON :', ''));
// //           setCandidateData((prevData) => {
// //             const newData = [...prevData, candidateJson];
// //             localStorage.setItem('candidates', JSON.stringify(newData)); // Save new data to localStorage
// //             return newData;
// //           });
// //         }
// //       } catch (error) {
// //         console.error('Failed to parse candidate JSON:', error);
// //       }
// //     });

// //     // Cleanup function to close the WebSocket connection
// //     return () => {
// //       socket.close();
// //     };
// //   }, []);

// //   const styles = {
// //     container: {
// //       position: 'relative',
// //       width: '80%', // Decreased to 80%
// //       height: '80vh', // Decreased to 80%
// //       paddingTop: '74.4px', // 80% of 93px
// //       paddingLeft: '151.2px', // 80% of 189px
// //       paddingRight: '151.2px', // 80% of 189px
// //     },
// //     title: {
// //       position: 'absolute',
// //       width: '183.2px', // 80% of 229px
// //       height: '27.2px', // 80% of 34px
// //       top: '74.4px', // 80% of 93px
// //       left: '151.2px', // 80% of 189px
// //       gap: '0px',
// //       opacity: '1',
// //       fontFamily: 'Inter',
// //       fontSize: '22.4px', // 80% of 28px
// //       fontWeight: 800,
// //       lineHeight: '27.112px', // 80% of 33.89px
// //       textAlign: 'left',
// //       color: 'rgba(255, 255, 255, 0.23)',
// //     },
// //     cardContainer: {
// //       position: 'relative',
// //       marginTop: '112px', // 80% of 140px
// //       display: 'grid',
// //       gridTemplateColumns: '152px 480px', // 80% of 190px and 600px
// //       columnGap: '480px', // 80% of 600px
// //       rowGap: '48px', // 80% of 60px
// //       justifyContent: 'start',
// //       alignItems: 'start',
// //     },
// //     card: {
// //       width: '416px', // 80% of 520px
// //       height: '479.2px', // 80% of 599px
// //       borderRadius: '8px 0px 0px 0px', // 80% of 10px 0px 0px 0px
// //       opacity: '1',
// //     },
// //   };
// //   return (
// //     <div style={styles.container}>
// //       <h1 style={styles.title}>Best Candidates</h1>
// //       <div style={styles.cardContainer}>
// //         {candidateData.map((candidate, index) => (
// //           <CandidateProfileCard key={index} candidateData={candidate} style={styles.card} />
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };
// // export default CandidatesPage;

// import React, { useState, useEffect } from 'react';
// import CandidateProfileCard from './CandidateProfileCard';

// const CandidatesPage = () => {
//   const [candidateData, setCandidateData] = useState(() => {
//     // Load candidate data from local storage if available
//     const savedData = localStorage.getItem('candidateData');
//     return savedData ? JSON.parse(savedData) : [];
//   });

//   useEffect(() => {
//     // Connect to the WebSocket server to receive candidate data
//     const socket = new WebSocket('ws://air.talanton.ai:4000/ws2');

//     // socket.addEventListener('open', () => {
//     //   console.log('WebSocket connection established');
//     // });

//     // socket.addEventListener('message', (event) => {
//     //   console.log('Message received from WebSocket:', event.data);
//     //   const message = event.data;
//     //   try {
//     //     if (message.startsWith('Candidate JSON :')) {
//     //       const candidateJson = JSON.parse(message.replace('Candidate JSON : ', ''));
//     //       setCandidateData((prevData) => {
//     //         const updatedData = [...prevData, candidateJson];
//     //         // Save updated data to local storage
//     //         localStorage.setItem('candidateData', JSON.stringify(updatedData));
//     //         console.log('Candidate data updated and saved to local storage');
//     //         return updatedData;
//     //       });

//     //claude
//     socket.addEventListener('message', (event) => {
//       console.log('Message received from WebSocket:', event.data);
//       const message = event.data;
//       try {
//         if (message.startsWith('Candidate JSON :')) {
//           // Extract the JSON part of the message
//           const jsonStart = message.indexOf('{');
//           const jsonEnd = message.lastIndexOf('}') + 1;
//           const jsonString = message.slice(jsonStart, jsonEnd);

//           // Parse the extracted JSON
//           const candidateJson = JSON.parse(jsonString);

//           setCandidateData((prevData) => {
//             const updatedData = [...prevData, candidateJson];
//             // Save updated data to local storage
//             localStorage.setItem('candidateData', JSON.stringify(updatedData));
//             console.log('Candidate data updated and saved to local storage');
//             return updatedData;
//           });
//         }
//       } catch (error) {
//         console.error('Failed to parse candidate JSON:', error);
//       }
//     });

//     //gpt
//     socket.addEventListener('message', (event) => {
//       console.log('Message received from WebSocket:', event.data);
//       const message = event.data;
//       try {
//         if (message.startsWith('Candidate JSON :')) {
//           // Remove the prefix
//           const jsonPart = message.substring('Candidate JSON : '.length);

//           // Find the last closing brace of the JSON object
//           const lastBraceIndex = jsonPart.lastIndexOf('}');

//           if (lastBraceIndex !== -1) {
//             // Extract only the JSON part
//             const jsonString = jsonPart.substring(0, lastBraceIndex + 1);

//             // Parse the JSON
//             const candidateJson = JSON.parse(jsonString);

//             setCandidateData((prevData) => {
//               const updatedData = [...prevData, candidateJson];
//               localStorage.setItem('candidateData', JSON.stringify(updatedData));
//               console.log('Candidate data updated and saved to local storage');
//               return updatedData;
//             });
//           } else {
//             throw new Error('Invalid JSON format: closing brace not found');
//           }
//         }
//       } catch (error) {
//         console.error('Failed to parse candidate JSON:', error);
//         // Log the raw message for debugging
//         console.log('Raw message:', message);
//       }
//     });
//     socket.addEventListener('close', () => {
//       console.log('WebSocket connection closed');
//     });

//     // Cleanup function to close the WebSocket connection
//     return () => {
//       socket.close();
//       console.log('WebSocket connection cleanup');
//     };
//   }, []);

//   const styles = {
//     container: {
//       position: 'relative',
//       width: '100%',
//       height: '100vh',
//       paddingTop: '93px',
//       paddingLeft: '189px',
//       paddingRight: '189px',
//     },
//     title: {
//       position: 'absolute',
//       width: '229px',
//       height: '34px',
//       top: '93px',
//       left: '189px',
//       gap: '0px',
//       opacity: '1',
//       fontFamily: 'Inter',
//       fontSize: '28px',
//       fontWeight: 800,
//       lineHeight: '33.89px',
//       textAlign: 'left',
//       color: 'rgba(255, 255, 255, 0.23)',
//     },
//     cardContainer: {
//       position: 'relative',
//       marginTop: '140px',
//       display: 'grid',
//       gridTemplateColumns: '190px 600px',
//       columnGap: '600px',
//       rowGap: '60px',
//       justifyContent: 'start',
//       alignItems: 'start',
//     },
//     card: {
//       width: '520px',
//       height: '599px',
//       borderRadius: '10px 0px 0px 0px',
//       opacity: '1',
//     },
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.title}>Best Candidates</h1>
//       <div style={styles.cardContainer}>
//         {candidateData.map((candidate, index) => (
//           <CandidateProfileCard
//             key={index}
//             candidateData={candidate}
//             style={styles.card}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CandidatesPage;

import React, { useState, useEffect } from "react";
import CandidateProfileCard from "./CandidateProfileCard";

const CandidatesPage = () => {
  const [candidateData, setCandidateData] = useState(() => {
    // Load candidate data from local storage if available
    const savedData = localStorage.getItem("candidateData");
    return savedData ? JSON.parse(savedData) : [];
  });

  useEffect(() => {
    // Connect to the WebSocket server to receive candidate data
    const socket = new WebSocket("ws://air.talanton.ai:4000/ws2");

    socket.addEventListener("message", (event) => {
      console.log("Message received from WebSocket:", event.data);
      const message = event.data;
      try {
        if (message.startsWith("Candidate JSON :")) {
          // Extract the JSON part of the message
          const jsonStartIndex = message.indexOf("{");
          const jsonEndIndex = message.lastIndexOf("}") + 1;
          let jsonString = message.substring(jsonStartIndex, jsonEndIndex);
          jsonString = jsonString.replace(/\n/g, " ");

          // Parse the extracted JSON
          const candidateJson = JSON.parse(jsonString);

          setCandidateData((prevData) => {
            const updatedData = [...prevData, candidateJson];
            // Save updated data to local storage
            localStorage.setItem("candidateData", JSON.stringify(updatedData));
            console.log("Candidate data updated and saved to local storage");
            return updatedData;
          });
        }
      } catch (error) {
        console.error("Failed to parse candidate JSON:", error);
      }
    });

    // Cleanup function to close the WebSocket connection
    return () => {
      socket.close();
      console.log("WebSocket connection cleanup");
    };
  }, []);

  const styles = {
    container: {
      position: "relative",
      width: "100%",
      height: "100vh",
      paddingTop: "93px",
      paddingLeft: "189px",
      paddingRight: "189px",
    },
    title: {
      position: "absolute",
      width: "229px",
      height: "34px",
      top: "93px",
      left: "189px",
      gap: "0px",
      opacity: "1",
      fontFamily: "Inter",
      fontSize: "28px",
      fontWeight: 800,
      lineHeight: "33.89px",
      textAlign: "left",
      color: "rgba(255, 255, 255, 0.23)",
    },
    cardContainer: {
      position: "relative",
      marginTop: "140px",
      display: "grid",
      gridTemplateColumns: "190px 600px",
      columnGap: "600px",
      rowGap: "60px",
      justifyContent: "start",
      alignItems: "start",
    },
    card: {
      width: "520px",
      height: "599px",
      borderRadius: "10px 0px 0px 0px",
      opacity: "1",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Best Candidates</h1>
      <div style={styles.cardContainer}>
        {candidateData.map((candidate, index) => (
          <CandidateProfileCard
            key={index}
            candidateData={candidate}
            style={styles.card}
          />
        ))}
      </div>
    </div>
  );
};

export default CandidatesPage;
