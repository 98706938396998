// // import React from 'react';

// // const CandidateProfile = () => {
// //   return (
// //     <div style={{ backgroundColor: 'black', padding: '20px' }}>
// //       <h1 style={{ color: 'white', textAlign: 'center' }}>Best Candidates</h1>
// //       <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
// //         <div style={{ backgroundColor: 'green', padding: '20px', borderRadius: '8px' }}>
// //           <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
// //             <img
// //               src="https://via.placeholder.com/50"
// //               alt="Candidate"
// //               style={{ width: '50px', height: '50px', borderRadius: '50%' }}
// //             />
// //             <div>
// //               <h2 style={{ color: 'white' }}>John Wick</h2>
// //               <p style={{ color: 'white' }}>2 years of experience</p>
// //             </div>
// //             <span style={{ marginLeft: 'auto', color: 'white' }}>...</span>
// //           </div>
// //           <p style={{ color: 'white', marginTop: '10px' }}>Notice period: 1 Month</p>
// //           <p style={{ color: 'white' }}>Location: Bangalore, India</p>
// //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
// //             <div>
// //               <p style={{ color: 'white' }}>CV Score</p>
// //               <div style={{ backgroundColor: 'white', height: '10px', width: '200px' }}>
// //                 <div style={{ backgroundColor: 'green', height: '10px', width: '160px' }}></div>
// //               </div>
// //             </div>
// //             <div>
// //               <p style={{ color: 'white' }}>Toingq call score</p>
// //               <div style={{ backgroundColor: 'white', height: '10px', width: '200px' }}>
// //                 <div style={{ backgroundColor: 'green', height: '10px', width: '160px' }}></div>
// //               </div>
// //             </div>
// //           </div>
// //           <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '10px' }}>
// //             <button
// //               style={{
// //                 backgroundColor: 'red',
// //                 color: 'white',
// //                 border: 'none',
// //                 padding: '10px 20px',
// //                 borderRadius: '4px',
// //                 cursor: 'pointer',
// //               }}
// //             >
// //               X
// //             </button>
// //             <button
// //               style={{
// //                 backgroundColor: 'green',
// //                 color: 'white',
// //                 border: 'none',
// //                 padding: '10px 20px',
// //                 borderRadius: '4px',
// //                 cursor: 'pointer',
// //               }}
// //             >
// //               ✓
// //             </button>
// //           </div>
// //         </div>
// //         <div style={{ backgroundColor: 'green', padding: '20px', borderRadius: '8px' }}>
// //           <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
// //             <img
// //               src="https://via.placeholder.com/50"
// //               alt="Candidate"
// //               style={{ width: '50px', height: '50px', borderRadius: '50%' }}
// //             />
// //             <div>
// //               <h2 style={{ color: 'white' }}>John Wick</h2>
// //               <p style={{ color: 'white' }}>2 years of experience</p>
// //             </div>
// //             <div style={{ marginLeft: 'auto', display: 'flex', gap: '10px' }}>
// //               <button
// //                 style={{
// //                   backgroundColor: 'green',
// //                   color: 'white',
// //                   border: 'none',
// //                   padding: '5px 10px',
// //                   borderRadius: '4px',
// //                   cursor: 'pointer',
// //                 }}
// //               >
// //                 View profile in detail
// //               </button>
// //               <button
// //                 style={{
// //                   backgroundColor: 'white',
// //                   color: 'green',
// //                   border: 'none',
// //                   padding: '5px 10px',
// //                   borderRadius: '4px',
// //                   cursor: 'pointer',
// //                 }}
// //               >
// //                 Download CV
// //               </button>
// //             </div>
// //           </div>
// //           <p style={{ color: 'white', marginTop: '10px' }}>Notice period: 1 Month</p>
// //           <p style={{ color: 'white' }}>Location: Bangalore, India</p>
// //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
// //             <div>
// //               <p style={{ color: 'white' }}>CV Score</p>
// //               <div style={{ backgroundColor: 'white', height: '10px', width: '200px' }}>
// //                 <div style={{ backgroundColor: 'green', height: '10px', width: '160px' }}></div>
// //               </div>
// //             </div>
// //             <div>
// //               <p style={{ color: 'white' }}>Toingq call score</p>
// //               <div style={{ backgroundColor: 'white', height: '10px', width: '200px' }}>
// //                 <div style={{ backgroundColor: 'green', height: '10px', width: '160px' }}></div>
// //               </div>
// //             </div>
// //           </div>
// //           <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '10px' }}>
// //             <button
// //               style={{
// //                 backgroundColor: 'red',
// //                 color: 'white',
// //                 border: 'none',
// //                 padding: '10px 20px',
// //                 borderRadius: '4px',
// //                 cursor: 'pointer',
// //               }}
// //             >
// //               X
// //             </button>
// //             <button
// //               style={{
// //                 backgroundColor: 'green',
// //                 color: 'white',
// //                 border: 'none',
// //                 padding: '10px 20px',
// //                 borderRadius: '4px',
// //                 cursor: 'pointer',
// //               }}
// //             >
// //               ✓
// //             </button>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default CandidateProfile;

// import React from 'react';

// const CandidateProfile = () => {
//   return (
//     <div style={{ backgroundColor: '#121212', padding: '20px', fontFamily: 'Arial' }}>
//       <h1 style={{ color: 'white', textAlign: 'center' }}>Best Candidates</h1>
//       <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
//         {/* Candidate Card */}
//         {['John Wick', 'John Wick'].map((name, index) => (
//           <div key={index} style={{ backgroundColor: '#004400', padding: '20px', borderRadius: '20px', width: '300px' }}>
//             <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//               <img
//                 src="https://via.placeholder.com/100"
//                 alt="Candidate"
//                 style={{ width: '50px', height: '50px', borderRadius: '50%' }}
//               />
//               <div style={{ flexGrow: 1 }}>
//                 <h2 style={{ color: 'white', margin: 0 }}>{name}</h2>
//                 <p style={{ color: 'white', margin: '5px 0' }}>2 years of experience</p>
//               </div>
//               <span style={{ color: 'white', cursor: 'pointer' }}>...</span>
//             </div>
//             <p style={{ color: 'white', marginTop: '10px' }}>Notice period: 1 Month</p>
//             <p style={{ color: 'white' }}>Location: Bangalore, India</p>
//             <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
//               <div>
//                 <p style={{ color: 'white' }}>CV Score</p>
//                 <div style={{ backgroundColor: '#ddd', borderRadius: '10px', height: '10px', width: '200px' }}>
//                   <div style={{ backgroundColor: '#00ff00', borderRadius: '10px', height: '10px', width: '160px' }}></div>
//                 </div>
//               </div>
//               <div>
//                 <p style={{ color: 'white' }}>Toingq call score</p>
//                 <div style={{ backgroundColor: '#ddd', borderRadius: '10px', height: '10px', width: '200px' }}>
//                   <div style={{ backgroundColor: '#00ff00', borderRadius: '10px', height: '10px', width: '160px' }}></div>
//                 </div>
//               </div>
//             </div>
//             <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '10px' }}>
//               <button
//                 style={{
//                   backgroundColor: 'red',
//                   color: 'white',
//                   border: 'none',
//                   padding: '10px 20px',
//                   borderRadius: '20px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 X
//               </button>
//               <button
//                 style={{
//                   backgroundColor: '#00ff00',
//                   color: 'white',
//                   border: 'none',
//                   padding: '10px 20px',
//                   borderRadius: '20px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 ✓
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CandidateProfile;

// import React from 'react';

// const CandidateProfileCard = ({ candidateData }) => {
//   const {
//     Name,
//     Experience,
//     'Notice Period': noticePeriod,
//     Location,
//     'CV Score': cvScore,
//     'Toingg Call Score': toingCallScore,
//     profile_link,
//   } = candidateData;

//   const styles = {
//     profileCard: {
//       backgroundColor: '#333',
//       color: '#fff',
//       padding: '16px',
//       borderRadius: '8px',
//       margin: '8px',
//       width: '300px',
//     },
//     profileHeader: {
//       display: 'flex',
//       alignItems: 'center',
//       marginBottom: '16px',
//     },
//     profileImage: {
//       width: '80px',
//       height: '80px',
//       borderRadius: '50%',
//       marginRight: '16px',
//     },
//     detail: {
//       display: 'flex',
//       marginBottom: '8px',
//     },
//     detailLabel: {
//       fontWeight: 'bold',
//       marginRight: '8px',
//     },
//     progressBar: {
//       display: 'flex',
//       alignItems: 'center',
//       marginBottom: '8px',
//     },
//     progressLabel: {
//       fontWeight: 'bold',
//       marginRight: '8px',
//     },
//     progressBarFill: {
//       height: '16px',
//       backgroundColor: '#4caf50',
//       borderRadius: '8px',
//     },
//     viewProfileButton: {
//       display: 'inline-block',
//       backgroundColor: '#4caf50',
//       color: '#fff',
//       textDecoration: 'none',
//       padding: '8px 16px',
//       borderRadius: '4px',
//       transition: 'background-color 0.3s ease',
//     },
//     viewProfileButtonHover: {
//       backgroundColor: '#45a049',
//     },
//   };

//   return (
//     <div style={styles.profileCard}>
//       <div style={styles.profileHeader}>
//       <img
//   className="profile-image"
//   src={candidateData.profileImage || "default/path/to/image"} // Example adjustment
//   alt="Profile"
//   style={styles.profileImage}
// />

//         <div className="profile-info">
//           <h2 className="name">{Name}</h2>
//           <p className="job-title">Current Job Title: UI/UX</p>
//         </div>
//       </div>
//       <div className="profile-details">
//         <div style={styles.detail}>
//           <span style={styles.detailLabel}>Experience:</span>
//           <span className="detail-value">{Experience}</span>
//         </div>
//         <div style={styles.detail}>
//           <span style={styles.detailLabel}>Notice Period:</span>
//           <span className="detail-value">{noticePeriod}</span>
//         </div>
//         <div style={styles.detail}>
//           <span style={styles.detailLabel}>Location:</span>
//           <span className="detail-value">{Location}</span>
//         </div>
//         <div style={styles.progressBar}>
//           <span style={styles.progressLabel}>CV Score</span>
//           <span className="progress-value">{cvScore}%</span>
//           <div style={{ ...styles.progressBarFill, width: `${cvScore}%` }}></div>
//         </div>
//         <div style={styles.progressBar}>
//           <span style={styles.progressLabel}>Toingg Call Score</span>
//           <span className="progress-value">{toingCallScore}%</span>
//           <div style={{ ...styles.progressBarFill, width: `${toingCallScore}%` }}></div>
//         </div>
//       </div>
//       <a
//         className="view-profile-button"
//         href={profile_link}
//         target="_blank"
//         rel="noopener noreferrer"
//         style={{
//           ...styles.viewProfileButton,
//           ...(profile_link ? {} : styles.viewProfileButtonHover),
//         }}
//       >
//         View profile in detail
//       </a>
//     </div>
//   );
// };

// export default CandidateProfileCard;

// import React from 'react';

// const CandidateProfileCard = ({ candidateData, style }) => {
//   const {
//     Name,
//     Experience,
//     'Notice Period': noticePeriod,
//     Location,
//     'CV Score': cvScore,
//     'Toingg Call Score': toingCallScore,
//     profile_link,
//     profileImage,
//   } = candidateData;

//   const styles = {
//     profileCard: {
//       backgroundColor: '#1c1c1e',
//       color: '#fff',
//       padding: '24px',
//       width: '520px',
//       height: '599px',
//       ...style, // Apply the passed style prop
//     },
//     profileHeader: {
//       display: 'flex',
//       alignItems: 'center',
//       marginBottom: '24px',
//     },
//     profileImage: {
//       width: '80px',
//       height: '80px',
//       borderRadius: '50%',
//       marginRight: '16px',
//     },
//     detail: {
//       display: 'flex',
//       marginBottom: '12px',
//     },
//     detailLabel: {
//       fontWeight: 'bold',
//       marginRight: '8px',
//       width: '120px',
//     },
//     progressBar: {
//       display: 'flex',
//       alignItems: 'center',
//       marginBottom: '12px',
//     },
//     progressLabel: {
//       fontWeight: 'bold',
//       marginRight: '8px',
//       width: '120px',
//     },
//     progressBarFill: {
//       height: '10px',
//       backgroundColor: '#4caf50',
//       borderRadius: '4px',
//     },
//     viewProfileButton: {
//       display: 'inline-block',
//       backgroundColor: '#4caf50',
//       color: '#fff',
//       textDecoration: 'none',
//       padding: '8px 16px',
//       borderRadius: '8px',
//       transition: 'background-color 0.3s ease',
//       marginTop: '24px',
//     },
//     viewProfileButtonHover: {
//       backgroundColor: '#45a049',
//     },
//   };

//   return (
//     <div style={styles.profileCard}>
//       <div style={styles.profileHeader}>
//         <img
//           src={profileImage || 'default/path/to/image'}
//           alt="Profile"
//           style={styles.profileImage}
//         />
//         <div className="profile-info">
//           <h2 className="name">{Name}</h2>
//           <p className="job-title">Current Job Title: UI/UX</p>
//         </div>
//       </div>
//       <div className="profile-details">
//         <div style={styles.detail}>
//           <span style={styles.detailLabel}>Experience</span>
//           <span className="detail-value">{Experience}</span>
//         </div>
//         <div style={styles.detail}>
//           <span style={styles.detailLabel}>Notice period</span>
//           <span className="detail-value">{noticePeriod}</span>
//         </div>
//         <div style={styles.detail}>
//           <span style={styles.detailLabel}>Location</span>
//           <span className="detail-value">{Location}</span>
//         </div>
//         <div style={styles.progressBar}>
//           <span style={styles.progressLabel}>CV Score</span>
//           <span className="progress-value">{cvScore}%</span>
//           <div style={{ ...styles.progressBarFill, width: `${cvScore}%` }}></div>
//         </div>
//         <div style={styles.progressBar}>
//           <span style={styles.progressLabel}>Toingg call score</span>
//           <span className="progress-value">{toingCallScore}%</span>
//           <div style={{ ...styles.progressBarFill, width: `${toingCallScore}%` }}></div>
//         </div>
//       </div>
//       <a
//         className="view-profile-button"
//         href={profile_link}
//         target="_blank"
//         rel="noopener noreferrer"
//         style={{
//           ...styles.viewProfileButton,
//           ...(profile_link ? {} : styles.viewProfileButtonHover),
//         }}
//       >
//         View profile in detail
//       </a>
//     </div>
//   );
// };

// export default CandidateProfileCard;

// import React from 'react';

// const CandidateProfileCard = ({ candidateData, style }) => {
//   console.log(candidateData)
//   const {
//     Name,
//     Experience,
//     'Notice Period': noticePeriod,
//     Location,
//     'CV Score': cvScore,
//     'Toingg Call Score': toingCallScore,
//     profile_link,
//     profileImage,
//   } = candidateData;

//   const styles = {
//     profileCard: {
//       backgroundColor: '#1c1c1e',
//       color: '#fff',
//       padding: '24px',
//       width: '520px',
//       height: '599px',
//       ...style, // Apply the passed style prop
//     },
//     profileHeader: {
//       display: 'flex',
//       alignItems: 'center',
//       marginBottom: '24px',
//     },
//     profileImage: {
//       width: '80px',
//       height: '80px',
//       borderRadius: '50%',
//       marginRight: '16px',
//     },
//     detail: {
//       display: 'flex',
//       marginBottom: '12px',
//     },
//     detailLabel: {
//       fontWeight: 'bold',
//       marginRight: '8px',
//       width: '120px',
//     },
//     progressBar: {
//       display: 'flex',
//       alignItems: 'center',
//       marginBottom: '12px',
//     },
//     progressLabel: {
//       fontWeight: 'bold',
//       marginRight: '8px',
//       width: '120px',
//     },
//     progressBarFill: {
//       height: '10px',
//       backgroundColor: '#4caf50',
//       borderRadius: '4px',
//     },
//     viewProfileButton: {
//       display: 'inline-block',
//       backgroundColor: '#4caf50',
//       color: '#fff',
//       textDecoration: 'none',
//       padding: '8px 16px',
//       borderRadius: '8px',
//       transition: 'background-color 0.3s ease',
//       marginTop: '24px',
//     },
//     viewProfileButtonHover: {
//       backgroundColor: '#45a049',
//     },
//   };

//   return (
//     <div style={styles.profileCard}>
//       <div style={styles.profileHeader}>
//         <img
//           src={profileImage || 'default/path/to/image'}
//           alt="Profile"
//           style={styles.profileImage}
//         />
//         <div className="profile-info">
//           <h2 className="name">{Name}</h2>
//           <p className="job-title">Current Job Title: UI/UX</p>
//         </div>
//       </div>
//       <div className="profile-details">
//         <div style={styles.detail}>
//           <span style={styles.detailLabel}>Experience</span>
//           <span className="detail-value">{Experience}</span>
//         </div>
//         <div style={styles.detail}>
//           <span style={styles.detailLabel}>Notice period</span>
//           <span className="detail-value">{noticePeriod}</span>
//         </div>
//         <div style={styles.detail}>
//           <span style={styles.detailLabel}>Location</span>
//           <span className="detail-value">{Location}</span>
//         </div>
//         <div style={styles.progressBar}>
//           <span style={styles.progressLabel}>CV Score</span>
//           <span className="progress-value">{cvScore}%</span>
//           <div style={{ ...styles.progressBarFill, width: `${cvScore}%` }}></div>
//         </div>
//         <div style={styles.progressBar}>
//           <span style={styles.progressLabel}>Toingg call score</span>
//           <span className="progress-value">{toingCallScore}%</span>
//           <div style={{ ...styles.progressBarFill, width: `${toingCallScore}%` }}></div>
//         </div>
//       </div>
//       <button>
//       <a
//         className="view-profile-button"
//         href={candidateData.profile_link}
//         target="_blank"
//         rel="noopener noreferrer"
//         style={{
//           ...styles.viewProfileButton,
//           ...(profile_link ? {} : styles.viewProfileButtonHover),
//         }}
//       >
//         View profile in detail
//         </a>
//       </button>
//     </div>
//   );
// };

// export default CandidateProfileCard;

// import React, { useState, useEffect } from 'react';
// import CandidateProfileCard from './CandidateProfileCard';

// const CandidatesPage = () => {
//   const [candidateData, setCandidateData] = useState(() => {
//     // Load candidate data from local storage if available
//     const savedData = localStorage.getItem('candidateData');
//     return savedData ? JSON.parse(savedData) : [];
//   });

//   useEffect(() => {
//     // Connect to the WebSocket server to receive candidate data
//     const socket = new WebSocket('ws://air.talanton.ai:4000/ws2');

//     socket.addEventListener('message', (event) => {
//       console.log(event.data);
//       const message = event.data.trim();
//       try {
//         if (message.startsWith('Candidate JSON :')) {
//           // Extract the JSON part of the message
//           const jsonString = message.slice('Candidate JSON :'.length).trim();

//           // Convert string to array and remove the last element
//           const jsonArray = jsonString.split('');
//           jsonArray.pop();

//           // Convert array back to string
//           const cleanJsonString = jsonArray.join('');

//           // Parse the extracted JSON
//           const candidateJson = JSON.parse(cleanJsonString);

//           setCandidateData((prevData) => {
//             const updatedData = [...prevData, candidateJson];
//             // Save updated data to local storage
//             localStorage.setItem('candidateData', JSON.stringify(updatedData));
//             console.log('Candidate data updated and saved to local storage');
//             return updatedData;
//           });
//         }
//       } catch (error) {
//         console.error('Failed to parse candidate JSON:', error);
//       }
//     });

//     // Cleanup function to close the WebSocket connection
//     return () => {
//       socket.close();
//       console.log('WebSocket connection cleanup');
//     };
//   }, []);

//   const styles = {
//     container: {
//       position: 'relative',
//       width: '100%',
//       height: '100vh',
//       paddingTop: '93px',
//       paddingLeft: '189px',
//       paddingRight: '189px',
//     },
//     title: {
//       position: 'absolute',
//       width: '229px',
//       height: '34px',
//       top: '93px',
//       left: '189px',
//       gap: '0px',
//       opacity: '1',
//       fontFamily: 'Inter',
//       fontSize: '28px',
//       fontWeight: 800,
//       lineHeight: '33.89px',
//       textAlign: 'left',
//       color: 'rgba(255, 255, 255, 0.23)',
//     },
//     cardContainer: {
//       position: 'relative',
//       marginTop: '140px',
//       display: 'grid',
//       gridTemplateColumns: '190px 600px',
//       columnGap: '600px',
//       rowGap: '60px',
//       justifyContent: 'start',
//       alignItems: 'start',
//     },
//     card: {
//       width: '520px',
//       height: '599px',
//       borderRadius: '10px 0px 0px 0px',
//       opacity: '1',
//     },
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.title}>Best Candidates</h1>
//       <div style={styles.cardContainer}>
//         {candidateData.map((candidate, index) => (
//           <CandidateProfileCard
//             key={index}
//             candidateData={candidate}
//             style={styles.card}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CandidatesPage;

import React, { useState } from "react"; // Import useState from React
import axios from "axios"; // Import axios for HTTP requests
import Snackbar from "@material-ui/core/Snackbar";
import ReactMarkdown from "react-markdown";

const CandidateProfileCard = ({ candidateData, style }) => {
  console.log(candidateData);
  const {
    Name,
    Experience,
    "Notice Period": noticePeriod,
    Location,
    "CV Score": cvScore,
    "Toingg Call Score": toingCallScore,
    profile_link,
    profileImage,
    position,
    call_summary,
    profile_details,
    candidate_id,
  } = candidateData;

  const [showPopup, setShowPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const styles = {
    profileCard: {
      backgroundColor: "rgba(217, 217, 217, 0.07)",
      color: "#fff",
      padding: "24px",
      width: "520px",
      minHeight: "599px", // Set minimum height
      ...style,
    },
    profileHeader: {
      display: "flex",
      alignItems: "center",
      marginBottom: "24px",
    },
    profileImage: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      marginRight: "16px",
    },
    profileInfo: {
      fontFamily: "Inter",
      textAlign: "left",
    },
    name: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "29.05px",
      margin: "0 0 8px 0",
    },
    jobTitle: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "21.78px",
      margin: "0",
    },
    detail: {
      display: "flex",
      marginBottom: "30px", // Adjust padding between fields
    },
    detailLabel: {
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "21.78px",
      textAlign: "left",
      marginRight: "8px",
      width: "120px",
    },
    detailValue: {
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "21.78px",
      textAlign: "left",
    },
    progressBarWrapper: {
      marginBottom: "60px", // Add extra margin between progress bars
    },
    progressLabel: {
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "21.78px",
      textAlign: "left",
      marginRight: "8px",
      width: "120px",
    },
    progressBarContainer: {
      width: "100%",
      height: "10px",
      backgroundColor: "#ffffff",
      borderRadius: "4px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
    },
    progressBarFill: {
      height: "100%",
      backgroundColor: "#4caf50",
      borderRadius: "4px",
      transition: "width 0.3s ease",
    },
    progressValue: {
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "21.78px",
      textAlign: "left",
    },
    viewProfileButton: {
      display: "inline-block",
      backgroundColor: "#4caf50",
      color: "#fff",
      textDecoration: "none",
      padding: "8px 16px",
      borderRadius: "8px",
      transition: "background-color 0.3s ease",
      marginTop: "24px",
    },
    viewProfileButtonHover: {
      backgroundColor: "#45a049",
    },
    popupOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    },
    popupContent: {
      backgroundColor: "#fff",
      color: "#000",
      padding: "24px",
      borderRadius: "8px",
      maxWidth: "80%",
      maxHeight: "80%",
      overflowY: "auto",
    },
    popupHeader: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "16px",
    },
    popupBody: {
      fontSize: "18px",
      lineHeight: "1.5",
    },
  };

  const handleViewProfileClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  // const handleDownloadCV = async () => {
  //   try {
  //     const response = await axios.get(`http://air.talanton.ai:4000/download_cv?candidate_id=${candidate_id}`);
  //     console.log('Download CV response:', response.data);
  //     setSnackbarMessage('CV downloaded successfully');
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     console.error('Error downloading CV:', error);
  //     setSnackbarMessage('Error downloading CV');
  //     setSnackbarOpen(true);
  //   }
  // };

  // const handleDownloadCV = async () => {
  //   try {
  //     const response = await axios.get(`http://air.talanton.ai:4000/download_cv?candidate_id=${candidate_id}`, {
  //       responseType: 'blob', // Set responseType to 'blob' to receive binary data
  //     });
  //     const contentDisposition = response.headers['content-disposition'];
  //     let fileName = 'cv.pdf'; // Default file name
  //     if (contentDisposition) {
  //       const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  //       const matches = filenameRegex.exec(contentDisposition);
  //       if (matches && matches[1]) {
  //         fileName = matches[1].replace(/['"]/g, '');
  //       }
  //     }
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     setSnackbarMessage('CV downloaded successfully');
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     console.error('Error downloading CV:', error);
  //     setSnackbarMessage('Error downloading CV');
  //     setSnackbarOpen(true);
  //   }
  // };
  const handleDownloadCV = () => {
    const downloadUrl = `http://air.talanton.ai:4000/download_cv?candidate_id=${candidate_id}`;
    window.open(downloadUrl, "_blank", "noopener,noreferrer,focus=false");
  };

  const handleAccept = async () => {
    try {
      const response = await axios.post(
        `http://air.talanton.ai:4000/accept?candidate_id=${candidate_id}`
      );
      console.log("Accept response:", response.data);
      setSnackbarMessage("Candidate accepted");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error accepting candidate:", error);
      setSnackbarMessage("Error accepting candidate");
      setSnackbarOpen(true);
    }
  };

  const handleReject = async () => {
    try {
      const response = await axios.post(
        `http://air.talanton.ai:4000/reject?candidate_id=${candidate_id}`
      );
      console.log("Reject response:", response.data);
      setSnackbarMessage("Candidate rejected");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error rejecting candidate:", error);
      setSnackbarMessage("Error rejecting candidate");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div style={{ ...styles.profileCard, height: "auto" }}>
      <div style={styles.profileHeader}>
        <img
          src={profileImage || "/images/download.png"}
          alt="Profile"
          style={styles.profileImage}
        />
        <div style={styles.profileInfo}>
          <h2 style={styles.name}>{Name}</h2>
          <p style={styles.jobTitle}>Current Job Title: {position}</p>
        </div>
      </div>
      <div className="profile-details">
        <div style={styles.detail}>
          <span style={styles.detailLabel}>Experience</span>
          <span style={styles.detailValue}>{Experience}</span>
        </div>
        <div style={styles.detail}>
          <span style={styles.detailLabel}>Notice Period</span>
          <span style={styles.detailValue}>{noticePeriod}</span>
        </div>
        <div style={styles.detail}>
          <span style={styles.detailLabel}>Location</span>
          <span style={styles.detailValue}>{Location}</span>
        </div>
        <div style={styles.progressBarWrapper}>
          <span style={styles.progressLabel}>CV Score</span>
          <div style={styles.progressBarContainer}>
            <div
              style={{ ...styles.progressBarFill, width: `${cvScore}%` }}
            ></div>
          </div>
          <span style={styles.progressValue}>{cvScore}%</span>
        </div>
        <div style={styles.progressBarWrapper}>
          <span style={styles.progressLabel}>Toingg Call Score</span>
          <div style={styles.progressBarContainer}>
            <div
              style={{ ...styles.progressBarFill, width: `${toingCallScore}%` }}
            ></div>
          </div>
          <span style={styles.progressValue}>{toingCallScore}%</span>
        </div>
      </div>
      <a
        href="#"
        onClick={handleViewProfileClick}
        style={{
          ...styles.viewProfileButton,
          ...(profile_link ? {} : styles.viewProfileButtonHover),
        }}
      >
        View profile in detail
      </a>

      {showPopup && (
        <div style={styles.popupOverlay} onClick={handlePopupClose}>
          <div style={styles.popupContent} onClick={(e) => e.stopPropagation()}>
            <div style={styles.popupHeader}>Profile Details</div>
            <div style={styles.popupBody}>{profile_details}</div>
            <div style={styles.popupHeader}>Call Summary</div>
            <div style={styles.popupBody}>
              <ReactMarkdown>{call_summary}</ReactMarkdown>{" "}
              {/* Use ReactMarkdown here */}
            </div>
            <button
              style={{ ...styles.viewProfileButton, float: "right" }}
              onClick={handleDownloadCV}
            >
              Download CV
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <button
                style={{
                  ...styles.viewProfileButton,
                  backgroundColor: "green",
                  marginRight: "8px",
                }}
                onClick={handleAccept}
              >
                &#10003; Accept
              </button>
              <button
                style={{
                  ...styles.viewProfileButton,
                  backgroundColor: "red",
                }}
                onClick={handleReject}
              >
                &#10005; Reject
              </button>
            </div>
          </div>
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};

export default CandidateProfileCard;
